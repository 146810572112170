import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter
} from 'react-router-dom'

import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, light, regular, thin } from '@fortawesome/fontawesome-svg-core/import.macro'
// import { thin } from "@fortawesome/pro-thin-svg-icons"


import App from './App';
import reportWebVitals from './reportWebVitals';
import { ModalProvider } from './contexts/modal.context'
import { ModalViewProvider } from './contexts/modalview.context'

config.autoAddCss = false

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FontAwesomeIcon icon={ light('user-tie-hair') } symbol="user-tie-hair"/>
    <FontAwesomeIcon icon={thin('tablet')} flip="vertical" symbol="tablet"/>
    <FontAwesomeIcon icon={light('chart-line')} symbol="chart-line"/>
    <FontAwesomeIcon icon={light('message')} symbol="message"/>
    <FontAwesomeIcon icon={light('xmark')} symbol="xmark"/>
    <FontAwesomeIcon icon={light('check')} symbol="check"/>
    {/* <FontAwesomeIcon icon="fa-light fa-check" /> */}
    {/* <FontAwesomeIcon icon="fa-light fa-xmark" /> */}
    <ModalProvider>
      <ModalViewProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ModalViewProvider>
    </ModalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
