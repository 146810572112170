import { atom } from 'jotai'

export const accessToken = atomWithWebStorage('settingsToken', '')
export const userData = atomWithWebStorage('settingsUsuarioData', '')

export function atomWithWebStorage(key, initialValue, storage = localStorage) {
  const baseAtom = atom(storage.getItem(key) ?? initialValue)
  return atom(
    (get) => get(baseAtom),
    (get, set, nextValue) => {
      set(baseAtom, nextValue)
      storage.setItem(key, nextValue)
    }
  )
}