import React from 'react'
import styles from './ComponentPage.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const ComponentPage = ({ children }) => {

  return (
    <div className={styles.page}>
      {children}
    </div>
    )
}

export default ComponentPage
