import React, { useEffect, useState } from 'react'
import styles from "./ReportInfo.module.scss"
import moment from 'moment'
import 'moment-timezone';
import { ReactComponent as LocationDot } from '../../../assets/images/location-dot.svg'
import { SelectButton, CheckButton, Map, Button } from '../../../components/reporteCiudadano'
import { isEmpty } from 'lodash'
import { currencyFormat } from '../../../utilities/helpers'
import api from '../../../services/api'
import { useAtom } from "jotai"
import { accessToken } from "../../../utilities/atoms"
import axios from 'axios'

const ReportInfo = ({data, toUpdate}) => {
  const [mapOpened, setMapOpened] = useState(false)
  const [openPhoto, setOpenPhoto] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState(null)
  const [token] = useAtom(accessToken)
  const [status, setStatus] = useState(data.status)
  const [boleta, setBoleta] = useState(data.fee_identifier)
  const [amount, setAmount] = useState(data.fee_amount)
  const [caja_pago, setCajaPago] = useState(data.fee_teller)
  const [condoned, setCondoned] = useState(data.fee_condoned !== null && data.fee_condoned !== undefined);
  const [tow, setTow] = useState(data.tow)
  const attending_at = moment(data.attending_at).tz('America/Monterrey').format('YYYY-MM-DD HH:mm:ss');
  const completed_at = moment(data.completed_at).tz('America/Monterrey').format('HH:mm:ss');
  const officer = data.officer
  const [image, setImage] = useState(null)
  const [imageName, setImageName] = useState(null)
  const [imageFile, setImageFile] = useState(null)
  const [officerComments, setOfficerComments] = useState(data.officer_comments)

  const options = {
    nuevo: 'new',
    rechazado: 'rejected',
    aprobado: 'accepted',
    notificado: 'notified',
    atendiendo: 'in progress',
    solucionado: 'completed',
    'no solucionado': 'completed with error',
    cerrado: 'closed',
    condonado: 'condoned',
    'no atendido' : 'ignored'
  }

  const gruas = {
    '': 'Ninguna',
    pacheco: 'Grúas Pacheco',
    victoria: 'Grúas Victoria',
    transito: 'Grúas Tránsito',
  }

  const closeItems = () => {
    setMapOpened(false)
    setOpenPhoto(false)
    setCurrentPhoto(null)
  }

  const onSubmit = (event) => {
    event.preventDefault()
  }

  // const openPhotoView = (photo) => {
  //   console.log('OpenPhotoView')
  //   console.log(photo)
  //   if (!openPhoto) { // Chequeo adicional para evitar abrir la vista si ya está abierta
  //     setCurrentPhoto(photo);
  //     setOpenPhoto(true);
  //   }
  // }

  const openMap = () => {
    // event.preventDefault()
    console.log('Openmap')
    if (!mapOpened) {
      setMapOpened(true)
    }
  }

  useEffect(() => {
    if (!isEmpty(currentPhoto)) {
      setOpenPhoto(true);
    }
  }, [currentPhoto])


  useEffect(() => {
    if (!isEmpty(status) || !isEmpty(boleta) || !isEmpty(amount) || !isEmpty(caja_pago) || !isEmpty(condoned) || !isEmpty(tow) || image || !isEmpty(officerComments)) {
      const updateData = {
        status: status != null ? status : null,
        fee_identifier: boleta != null ? boleta : null,
        fee_amount: amount != null ? amount : null,
        fee_teller: caja_pago != null ? caja_pago : null,
        office: data.office_id != null ? data.office_id : null,
        category: data.category_id != null ? data.category_id : null,
        level: data.level_raw != null ? data.level_raw : null,
        tow: tow != null ? tow : null,
        officer_comments: officerComments
      }

      if (condoned === 'true') {
        // const currentDay = moment().format("YYYY-MM-DD HH:mm:ss")
        const currentDay = moment().toISOString()
        if (isEmpty(data.fee_condoned)) {
          updateData.fee_condoned = currentDay
        }
      } else {
        updateData.fee_condoned = null
      }

      if (image) {
        //const config = { responseType: 'blob' }
        updateData.image = imageFile
      }

      console.log('update', updateData)
      toUpdate(updateData)
    }
  }, [status, boleta, amount, caja_pago, condoned, tow, image, officerComments])

  useEffect(() => {
    if (data.status && Object.keys(options).includes(data.status)) {
      setStatus(options[data.status]);
    }
  }, [data.status]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleCondonedChange = (e) => {
    setCondoned(e.target.value);
  };

  const photoView = (
    <div className={styles.mapContainer}>
      <div className={styles.photoView} style={{
        backgroundImage: `url(${
          currentPhoto ?? ''
        })`
      }}>
      </div>
      <Button onClick={closeItems} type="default">Cerrar Foto</Button>
    </div>
  )

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      //setImageName(event.target.files[0].name)
      setImageFile(event.target.files[0])
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  }

  const mapLocation = (
    <div className={styles.mapContainer}>
      <Map
        zoom={20}
        lat={data.lat}
        lng={data.lng}
      />
      <Button onClick={closeItems} type="default">Cerrar Mapa</Button>
    </div>
  )

  const formatCurrency = (value) => {
    if (!value) return ''
    const amountNumber = parseFloat(value.replace(/[^\d.]/g, ''))
    if (isNaN(amountNumber)) return ''
    return amountNumber.toFixed(2)
  }

  const handleChange = (event) => {
    const formattedValue = formatCurrency(event.target.value)
    setAmount(formattedValue)
  }

  const FormInfo = (
    <>
      <form action="#" onSubmit={onSubmit} method="post">
        <div className={styles.formGroup}>
          <div className={styles.item}>
            <label htmlFor="folio">Folio</label>
            <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
          </div>
          <div className={styles.item}>
            <label htmlFor="created_at">Fecha de reporte</label>
            <input type="text" id="fecha"
                   value={moment(data.created_at).tz('America/Monterrey').format("DD/MM/YYYY - HH:mm") ?? '-'}
                   readOnly/>
          </div>
          <div className={styles.item}>
            <label htmlFor="date_atention">Hora de Atención</label>
            <input type="text" id="fecha" value={attending_at} readOnly/>
            {/* <input type="text" id="fecha" value={ data.completed_at ? moment(data.completed_at).format("DD/MM/YYYY - HH:mm") : '-'} readOnly/> */}
          </div>
          <div className={styles.item}>
            <label htmlFor="date_solution">Hora de Solucionado</label>
            <input type="text" id="fecha"
                   value={completed_at !== 'Invalid date' ? completed_at : '-'} readOnly/>
            {/* <input type="text" id="fecha" value={ data.completed_at ? moment(data.completed_at).format("DD/MM/YYYY - HH:mm") : '-'} readOnly/> */}
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.item}>
            <label htmlFor="secretaria">Secretaría</label>
            <input type="text" id="secretaria" value={data.office ?? '-'} readOnly/>
          </div>
          <div className={styles.item}>
            <label htmlFor="categoria">Categoría</label>
            <input type="text" id="categoria" value={data.category ?? '-'} readOnly/>
          </div>
          <div className={styles.item}>
            <label htmlFor="process">Proceso</label>
            <select id="process" value={status} onChange={handleStatusChange}>
              {Object.entries(options).map(([key, value]) => (
                  <option key={value}
                          value={value}>{key.charAt(0).toUpperCase() + key.slice(1)}</option>
              ))}
            </select>

            {/* <input type="text" id="fecha" value={ data.status?.charAt(0).toUpperCase() + data.status?.slice(1).toLowerCase() ?? '-'}/> */}
          </div>
          <div className={styles.item}>
            <label htmlFor="officer">Oficial</label>
            <input type="text" id="officer" value={officer} readOnly/>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={styles.item}>
            <label htmlFor="crane">Grúa</label>
            <select id="crane" value={tow ?? ''} onChange={(e) => setTow(e.target.value)}>
              {Object.entries(gruas).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
              ))}
            </select>
            {/* <input type="text" id="crane" value={tow ?? '-'} onChange={ (e) => setTow(e.target.value) }/> */}
          </div>
          <div className={styles.item}>
            <label htmlFor="infraccion">Boleta de infracción</label>
            <input
                type="text"
                id="categoria"
                value={boleta}
                onChange={(e) => setBoleta(e.target.value)}
            />
          </div>
          <div className={styles.item}>
            <label htmlFor="infraccion_monto">Monto de infracción</label>
            <input type="text" id="fecha" value={amount ? `$${amount}` : ''}
                   onChange={handleChange}/>
          </div>
          <div className={styles.item}>
            <label htmlFor="fee_teller">Caja de pago</label>
            <input type="text" id="fecha" value={caja_pago}
                   onChange={(e) => setCajaPago(e.target.value)}/>
          </div>
        </div>
        <div className={styles.formGroup}>
          <div className={[styles.item, styles.full].join(' ')}>
            <label htmlFor="direccion">Dirección</label>
            <div className={styles.directionInput}>
              <input type="text" id="direccion"
                     value={(data.street + ', CP.' + data.zipcode + ', ' + data.state).slice(0, 80)}
                     readOnly/>
              <div className={styles.itemMap} onClick={openMap}>
                <div className="locationDot">
                  <LocationDot/>
                </div>
                <p>VER MAPA</p>
              </div>
            </div>
            {/* <input type="text" id="direccion" value={data.street + ', CP.' + data.zipcode + ', ' + data.state} readOnly/> */}
          </div>
          <div className={styles.item}>
            <label htmlFor="condoned">¿Boleta Condonada?</label>
            <select id="condoned" value={condoned} onChange={handleCondonedChange}>
              <option value={true}>Si</option>
              <option value={false}>No</option>
            </select>
          </div>
        </div>
        <div className={[styles.formGroup, styles.photosContainer].join(' ')}>
          <div
              className={[styles.item, styles.open].join(' ')}/*onClick={openPhotoView(data.picture)}*/>
            <label htmlFor="photo_report">Foto del reporte</label>
            <div className={styles.fotoContainer}
                 onClick={(event) => setCurrentPhoto(data.picture)}
                 style={{backgroundImage: `url(${data.picture ?? ''})`}}>
              <div className={[styles.pill, styles[data.level] ?? ''].join(' ')}>
                <div className={[styles.square, styles[data.level] ?? ''].join(' ')}></div>
                <p>{data.level?.toUpperCase()}</p>
              </div>
            </div>
            <label htmlFor="coments_user">Comentarios del ciudadano</label>
            <div className={styles.dataText}>{data.comment ?? '-'}</div>
            {/* <input type="text" id="coments_user" value={ data.comment ?? '-'} readOnly/> */}
          </div>
          <div
              className={[styles.item, styles.open].join(' ')}/*onClick={openPhotoView(data.officer_picture)}*/>
            <label htmlFor="photo_officer">Foto del oficial</label>
            {data.officer_picture ?
                <div className={styles.fotoContainer}
                     onClick={(event) => setCurrentPhoto(data.officer_picture)}
                     style={{backgroundImage: `url(${data.officer_picture ?? ''})`}}>
                </div> : <div className={styles.fotoContainer}>
                  <label
                      htmlFor="officer-upload" className={styles.uploadClick}>
                    <div className={styles.clicText}>
                      {image &&
                          <img alt="preview image" src={image} className={styles.previewImage}/>}
                      <p>Clic para seleccionar foto.</p></div>
                    <input id="officer-upload"
                           onChange={onImageChange}
                           name="officer-upload"
                           type="file"
                           className={styles.upload}/>
                  </label>
                </div>}
            <label htmlFor="officer_comments">Comentarios del oficial</label>
            <textarea name="officer_comments" id="officer_comments" className={styles.dataText2} value={officerComments} onChange={(e) => setOfficerComments(e.target.value)}/>
            {/* <input type="text" id="coments_officer" value={ data.officer_comments ?? '-'} readOnly/> */}
          </div>
        </div>
      </form>
    </>
  )

  return (<>
    <div className={ [styles.Formcontent, openPhoto && styles.centered, mapOpened && styles.centered].join(' ') }>
      {!mapOpened && !openPhoto && FormInfo}
      {mapOpened && mapLocation}
      {openPhoto && photoView}
    </div>
  </>)
}

export default ReportInfo