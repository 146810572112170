import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { BASE_URL } from '../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import styles from "./Login.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../../services/api'
import { useAtom } from 'jotai'
import {jwtDecode} from 'jwt-decode'
import {
  accessToken,
  userData
} from '../../utilities/atoms'

const Login = () => {
  const navigate = useNavigate()
  // const [user, setUser] = useState('');
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [token, setToken] = useAtom(accessToken)
  const [usuario, setUsuario] = useAtom(userData)
  const eyeIcon = regular('eye')
  const eyeSlashIcon = regular('eye-slash')
  const [showPassword, setShowPassword] = useState(false)
  const [showRecover, setShowRecover] = useState(false)
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  useEffect(() => {
    // loggerDebug('check')
    // loggerDebug(usuario, token, error)
    if ( (!isEmpty(usuario) && usuario !== 'null') && !isEmpty(token) && !error) {
      // loggerDebug('redirect')
      navigate(`${ BASE_URL }/`)
    }
  }, [usuario, token, error])

  const ShowPass = () => {
    setShowPassword(!showPassword)
  };

  const handleRecover = () => {
    showRecover ? setShowRecover(false) : setShowRecover(true)
  }

  const recoveryPassword = (event) => {
    event.preventDefault()
    console.log('Recovery Password')
    // if (!isEmpty(email)) {
      api
      .resetPassword(email)
      .then((response) => {
        console.log(response)
        setErrorEmail(false)
        successChangePassword()
        setPasswordChanged(true)

      })
      .catch(error => {
        const data = error.response.data
        const errorMessage = data.message
        console.log(errorMessage)
        setErrorEmail(true)
        setPasswordChanged(false)
      })
    // }
    
    // passwordChanged ? setPasswordChanged(false) : setPasswordChanged(true)
  }

  const login = (event) => {
    event.preventDefault()
    console.log('Login')
    console.log(password)
    api
      .loginUser(email, password)
      .then((response) => {
        const data = response.data
        const user = data.user
        const decodedToken = jwtDecode(data.access_token)
        console.log('decodedToken')
        console.log(decodedToken)
        setError(false)
        setUsuario(user.name)
        setToken(data.access_token)
        // loggerDebug(response.data)
        // const data = response.data

        // loggerDebug(data)
        // setUsuarioId(data.usuario_id)
        // setToken(data.token)

        // Sentry.setUser({
        //   id: data.usuario_id,
        //   username: data.cveUsuario,
        //   pos_id: data.puntoVenta_id,
        //   pos: data.nombPuntoVenta,
        // })
      })
      .catch(error => {
        const data = error.response.data
        const errorMessage = data.message
        console.log(errorMessage)
        setError(true)
        // loggerError(error)
        // const data = error.response.data
        // loggerError(data)

        // setLocalLoading(false)
        // setupModal({
        //   title: 'Atención',
        //   content: (<><p>{ data.error }</p></>),
        // })
        // showModal()
      })
    //
    // error ? setError(true) : null
    // if ( !error ) {
    //   setError(true)
    // }
    // setError(true)
  }

  const passwordInputType = showPassword ? 'text' : 'password'

  const successChangePassword = () => {
    return (
      <form className={styles.loginForm}>
        <div className={styles.loginHeader}>
          <FontAwesomeIcon
            icon={solid('circle-check')}
            className={styles.icon}
          />
          <label className={ styles.success }>¡Excelente!</label>
          <p>
          Hemos enviado un correo electrónico con las instrucciones para recuperar tu contraseña a la dirección que nos proporcionaste.
          </p>
        </div>
        <div className={styles.loginFormFields}>
          <button type="submit" className={styles.loginButton}>
          Ir al Inicio
          </button>
        </div>
      </form>
    )
  }

  return (
    <div className={ styles.loginContainer }>
      <div className={ styles.loginInnerContainer }>
        {!showRecover ? <>
          <form className={ styles.loginForm }>
            <div className={ styles.loginHeader }>
              <figure className={ styles.image } />
              <div className={ styles.info }>
                <label>Inicio de Sesión</label>
                <p>
                  Para ingresar escribe el usuario y la contraseña que le fueron
                  asignados.
                </p>
              </div>
              { error && <div className={styles.errorBox}>
              Verifica tu nombre de usuario y contraseña e intenta de nuevo.
              </div>
              }
            </div>
            
            <div className={styles.loginFormFields}>
              <div className={styles.loginFormField}>
                <label>Usuario</label>
                <input value={email} placeholder="correo@victoria.gob.mx" onChange={(event) => setEmail(event.target.value)} />
              </div>
              <div className={[styles.loginFormField, styles.password].join(' ')}>
                <label>Contraseña</label>
                <div className={styles.passwordInputContainer}>
                  <input
                    type={passwordInputType}
                    placeholder="••••••••"
                    value={ password }
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <FontAwesomeIcon
                    icon={showPassword ? eyeIcon : eyeSlashIcon}
                    flip="horizontal"
                    className={styles.icon}
                    onClick={ShowPass}
                  />
                </div>
              </div>
            </div>
            {/* <div></div> */}
            <div className={ styles.loginFooter }>
              <button className={styles.loginButton} onClick={ login }>
                Ingresar
              </button>
              <p className={styles.forgotPassword} onClick={() => {
                setEmail(null)
                setError(false)
                handleRecover()
                }}
              >Recuperar contraseña</p>
            </div>
          </form>
        </>
        :
        <>
          {passwordChanged === true ? successChangePassword() : 
            <form className={styles.loginForm}>
              <div className={styles.loginHeader}>
                <figure className={styles.image} />
                <div className={ styles.info }>
                  <label>Recuperar Contraseña</label>
                  <p>
                    Si has olvidado tu contraseña, no te preocupes. ingresa tu correo electrónico y te enviaremos un correo con instrucciones para recuperarla.
                  </p>
                </div>
                { errorEmail && <div className={styles.errorBox}>
                  Ingresa un correo electrónico válido.
                  </div>
                }
              </div>
              <div>
              </div>
              <div className={styles.loginFormFields}>
                <div className={styles.loginFormField}>
                  <label>Correo electrónico</label>
                  <input 
                    placeholder="correo@victoria.gob.mx"
                    value={ email }
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <button className={styles.loginButton}  onClick={ recoveryPassword }>
                Recuperar contraseña
                </button>
              </div>
              <div className={ [styles.forgotPassword, styles.return].join(' ') }>
              <FontAwesomeIcon
                    icon={regular('arrow-right')}
                    rotation={180}
                    className={styles.icon}
                    onClick={ShowPass}
              />
                <p /*className={styles.forgotPassword}*/ onClick={() => { 
                  handleRecover()
                  setErrorEmail(false)
                  }}>Regresar</p>
              </div>
              
            </form>
          }
        </>
        }
        <div className={ styles.containerBackgorund }>
          <figure className={styles.loginBackground} />
        </div>
      </div>
    </div>
  )
}

export default Login;
