import React from 'react'

const DeleteAccountInstructions = () => {
  return (
    <div>
      delete account
    </div>
  )
}

export default DeleteAccountInstructions