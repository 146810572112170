import React, { useEffect, useRef, useState } from 'react'
import styles from "./TagComponent.module.scss"

const TagComponent = ({key, className, children}) => {

  return (
    <span key={key} className={[styles.tag, ...className].join(' ')}>{children}</span>
  )
}

export default TagComponent