import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import styles from "./Boleta.module.scss"
import { format, formatISO, parseISO, parse, set } from 'date-fns'

const Boleta = forwardRef(({identifier, onValueChange = (key, value) => {}}, ref) => {
  // forwardRef(({toCreate, toEdit, data = null, type = null}, ref)
  const [folio, setFolio] = useState(identifier)
  const [fecha, setFecha] = useState(formatISO(new Date()))
  const [caja, setCaja] = useState('')
  const [amount, setAmount] = useState('')
  const [condoned, setCondoned] = useState(false)
  const [errors, setErrors] = useState(true)

  console.debug(fecha)
  console.debug(format(parseISO(fecha), 'dd/MM/yyyy'))

  useImperativeHandle(ref, () => ({
    setErrorBag(errors) {
      handleErrorBag(errors)
    }
  }))

  const handleErrorBag = (errors) => {
    setErrors(errors)
  }

  useEffect(() => {
    let isoDate = formatISO(new Date())
    console.debug(isoDate)
    setFecha(isoDate)
    onValueChange('fee_payment_date', isoDate)
  }, [])

  return (<div className={styles.boletaContainer} ref={ref}>
    <div className={styles.formGroup}>
      <div className={ [styles.item, styles.date].join(' ')}>
        <label htmlFor="fecha">Fecha de pago</label>
        <input
          className={styles.input}
          type="date"
          name={'fecha'}
          placeholder={'dd/mm/aaaa'}
          pattern="\d{2}/\d{2}-\d{4}"
          value={format(parseISO(fecha), 'yyyy-MM-dd')}
          onChange={(event) => {
            let isoDate = formatISO(parse(event.target.value, 'yyyy-MM-dd', new Date()))
            console.debug(isoDate)
            setFecha(isoDate)
            onValueChange('fee_payment_date', isoDate)
          }}
        />
        { errors?.fee_payment_date?.message && 
          <div className={styles.errorBox}>
            {errors?.fee_payment_date?.message}
          </div>
        }
      </div>
      <div className={styles.item}>
        <label htmlFor="caja">Caja en la que se pagó</label>
        <input
          className={styles.input}
          type="text"
          name={'caja'}
          placeholder={''}
          value={caja}
          onChange={(event) => {
            setCaja(event.target.value)
            onValueChange('fee_teller', event.target.value)
          }}
        />
        { errors?.fee_teller?.message && 
          <div className={styles.errorBox}>
            {errors?.fee_teller?.message}
          </div>
        }
      </div>
    </div>
    <div className={styles.formGroup}>
      <div className={styles.item}>
        <label htmlFor="folio">Folio de la boleta</label>
        <input
          className={styles.input}
          type="text"
          name={'folio'}
          disabled={true}
          placeholder={''}
          value={folio}
          onChange={(event) => {
            setFolio(event.target.value)
            onValueChange('fee_identifier', event.target.value)
          }}
        />
      </div>
      <div className={styles.item}>
        <label htmlFor="amount">Monto pagado</label>
        <input
          className={styles.input}
          type="text"
          name={'amount'}
          placeholder={''}
          value={amount}
          onChange={(event) => {
            setAmount(event.target.value)
            onValueChange('fee_amount', event.target.value)
          }}
        />
        { errors?.fee_amount?.message && 
          <div className={styles.errorBox}>
            {errors?.fee_amount?.message}
          </div>
        }
      </div>
    </div>
    <div className={styles.formGroup}>
    <div className={styles.item}>
      <label htmlFor="condonado">Condonado</label>
      <input
        className={styles.checkbox}
        type="checkbox"
        name={'condonado'}
        placeholder={''}
        value={ condoned } 
        onChange={(event) => {
          setCondoned(event.target.checked)
          onValueChange('fee_condoned', event.target.checked)
        }}
      />
    </div>
    </div>
  </div>)
})

export default Boleta