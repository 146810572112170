import { GoogleMap,/* Marker,*/ useLoadScript } from "@react-google-maps/api";
import {MarkerF} from '@react-google-maps/api'
import { useMemo } from "react";
import styles from "./MapComponent.module.scss";

const MapComponent = (props) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const lat = parseFloat(props.lat) ?? 0
  const lng = parseFloat(props.lng) ?? 0
  const center = useMemo(() => ({ lat: lat, lng: lng }), []);
  const zoom = props.zoom ?? 10
  return (
    <div className={ styles.Container }>
      {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName={styles.Map}
          center={center}
          zoom={zoom}
        >
          <MarkerF position={{ lat: lat, lng: lng }} />
        </GoogleMap>
      )}
    </div>
  )
}

export default MapComponent