import axios from 'axios';

const urlBase = 'https://api.reporteciudadano.app';

/**
 * @param {string}  url url a la cual consultar
 * esta funcion detecta si es una nueva url base (comienza con http:// o https://).
 * en caso de ser asi, retorna la url. en caso contrario, se asume que es un fragmento
 * de path por lo que se concatena con la constante urlBase
 **/
const readUrl = (url = '') =>
    url.startsWith('http://') || url.startsWith('https://') ? url : `${urlBase}/${url}`

const post = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    },
    timeout: 10000
})

const put = (url = '', body = {}, headers = {}) => axios.put(readUrl(url), body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...headers,
    },
    timeout: 10000
})

const putMultipart = (url = '', body = {}, headers = {}) => axios.post(readUrl(url), body, {
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        ...headers,
    },
    timeout: 10000
})

const get = (url = '', headers = {}, options = {}) => axios.get(readUrl(url), {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    },
    ...options,
    timeout: 60000
  })

const loginUser = (email, password) => post('login', {
    // 'name': name,
    'email': email,
    'password': password,
})

const resetPassword = (email) => post('forgot-password', {
    'email': email,
})

const reports = (token) => get(`dashboard/reports`,{
    'Authorization': `Bearer ${token}`
})

const PublicReports = (token) => get(`dashboard/public_reports`,{
    'Authorization': `Bearer ${token}`
})

const updateReport = (token, id, data) => putMultipart(`dashboard/reports/${id}`, data, {
    'Authorization': `Bearer ${token}`
})

const updatePublicReport = (token, id, data) => putMultipart(`dashboard/public_reports/${id}`, data, {
    'Authorization': `Bearer ${token}`
})

const updateReportFee = (token, id, data) => put(`dashboard/reports/${id}/fee`, data, {
    'Authorization': `Bearer ${token}`
})

const printReport = (token, id) => get(`dashboard/reports/${id}/print`,{
    'Authorization': `Bearer ${token}`
}, {responseType: 'blob'})

const printPublicReport = (token, id) => get(`dashboard/public_reports/${id}/print`,{
    'Authorization': `Bearer ${token}`
}, {responseType: 'blob'})

const catalogOffices = (token) => get(`dashboard/catalogs/offices`,{
    'Authorization': `Bearer ${token}`
})

const catalogCategories = (token, office) => get(`dashboard/catalogs/offices/${office}/categories`,{
    'Authorization': `Bearer ${token}`
})

const adminUsers = (token) => get(`dashboard/users`,{
    'Authorization': `Bearer ${token}`
})

const createUser = (token, data) => post(`dashboard/users/new`, data, {
    'Authorization': `Bearer ${token}`
})

const updateUser = (token, id, data) => put(`dashboard/users/${id}`, data, {
    'Authorization': `Bearer ${token}`
})

const statistics = (module_statistics, token, data) => post(`dashboard/`+module_statistics+`/statistics/list`, data, {
    'Authorization': `Bearer ${token}`
})

const Api = {
    loginUser,
    resetPassword,
    reports,
    PublicReports,
    updateReport,
    updatePublicReport,
    updateReportFee,
    adminUsers,
    createUser,
    updateUser,
    catalogOffices,
    catalogCategories,
    statistics,
    printReport,
    printPublicReport
}

export default Api

////
// const api = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// export const registerUser = async (userData) => {
//   const response = await api.post('/register', userData);
//   return response.data;
// };

// export const loginUser = async (email, password) => {
//   const response = await api.post('/login', { email, password });
//   return response.data;
// };

// export const forgotPassword = async (email) => {
//   const response = await api.post('/forgot-password', { email });
//   return response.data;
// };

// export const resetPassword = async (resetPasswordData) => {
//   const response = await api.post('/reset-password', resetPasswordData);
//   return response.data;
// };
