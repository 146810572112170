import React from 'react'
import { Route } from 'react-router-dom'
import ProtectedRoute from '../../components/navigation/PrivateRoute'

import Index from './Index'
import { BASE_URL } from '../../utilities/constants'

const routes = (
  <Route exact path={`${BASE_URL}/statistics`}>
    <Route exact path="" element={<ProtectedRoute component={ <Index/>}/>}/>
  </Route>
)

export default routes
