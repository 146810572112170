const ModuleByTypeUser = {
    type: (type) => {
        if (type.includes('admin')) {
            return 'reports'
        } else if (type.includes('admin-services')) {
            return 'public_reports'
        }
      },
}

export default ModuleByTypeUser