import React from 'react'
import * as Styled from './ComponentModal.styled'
import { DestructiveButton, PositiveButton } from './ReporteCiudadano.styled'
import { Button } from './'

const ComponentModal = ({
                          show,
                          title,
                          children,
                          positiveButtonTitle,
                          negativeButtonTitle,
                          actionButtonTitle,
                          onClose,
                          onAccept,
                          buttonType
                        }) => {

  if (!show) {
    console.log('null')
    return null
  }

  return (
    <Styled.Modal>
      <Styled.ModalContent>
        <Styled.ModalHeader>
          <Styled.ModalTitle>{ title }</Styled.ModalTitle>
        </Styled.ModalHeader>
        <Styled.ModalBody>
          { children }
        </Styled.ModalBody>
        <Styled.Footer>
          { negativeButtonTitle &&
          <DestructiveButton type={ buttonType }
                             onClick={ onClose }>{ negativeButtonTitle ?? 'Cancelar' }</DestructiveButton> }
          { positiveButtonTitle &&
            <PositiveButton type={ buttonType } onClick={ onAccept }>{ positiveButtonTitle }</PositiveButton> }
          { actionButtonTitle && <Button onClick={ onAccept } type="action">{ actionButtonTitle }</Button> }
        </Styled.Footer>
      </Styled.ModalContent>
    </Styled.Modal>
  )
}

export default ComponentModal
