import React from 'react'
// import * as Sentry from '@sentry/react'
import { isEmpty } from 'lodash'
import {Navigate} from 'react-router-dom'
import {useAtom} from 'jotai'
import {
    accessToken,
    userData
} from '../../utilities/atoms'
//import {isauth}  from 'auth'

const ProtectedRoute = ({component}) => {
    const [usuario,] = useAtom(userData)
    const [token,] = useAtom(accessToken)

    const checkAuth = () => {
      return (!isEmpty(usuario) && usuario !== 'null') && !isEmpty(token)
    }

    const authed = checkAuth()
    
    if (!authed) {
        // Sentry.setUser(null)
        console.log('unauthenticated user')
    }

    return authed ? component : <Navigate to={'/login'}/>
}

export default ProtectedRoute