import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import {
  Menu,
  User,
  Options,
  Page,
  Datatable,
  Form, Tag,
  Button,
} from '../../components/reporteCiudadano'
import { useAtom } from 'jotai'
import api from '../../services/api'
import { BASE_URL } from '../../utilities/constants'
import { useModal } from '../../contexts/modal.context'
import { useModalView } from '../../contexts/modalview.context'
import { accessToken, } from '../../utilities/atoms'
// import '../../scss/Layout.scss'
import styles from './Index.module.scss'
// import Module from './components/Module'
import '../../scss/Layout.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReportInfo from "./partials/ReportInfo"
import download from 'downloadjs'
import { useNavigate } from 'react-router-dom'
import { ModuleType } from '../../components/enums'
import { jwtDecode } from 'jwt-decode';


const Index = () => {
  const {showModal, hideModal, setupModal} = useModal()
  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const [reportes, setReportes] = useState(null)
  const dataUpdateForm = useRef({})
  const dataBoletaForm = useRef({})
  const [token] = useAtom(accessToken)
  const [closeModal, setCloseModal] = useState(false)
  const [busqueda, setBusqueda] = useState('')
  const ref = useRef(null);
  const [pdfUrl, setPdfUrl] = useState(null)
  const [report, setReport] = useState(null)
  const [openReport, setOpenReport] = useState(false)
  const [moduleReport, setModuleReport] = useState('')
  const navigate = useNavigate()

  function validateBoleta (formData) {
    let errors = {}
    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      ref.current.setErrorBag(errors);
      return false;
    }

  }

  const getDataReports = () => {
    api
      .PublicReports(token)
      .then((response) => {
        const reports = response.data
        setReportes(reports)
        // const user = data.user
        console.log(reports)
      })
      .catch(error => {
        // const data = error.response.data
        // const errorMessage = data.message
        console.log(error)
        // setError(true)
      })
  }

  useEffect(() => {
    if (isEmpty(moduleReport) && !isEmpty(token)) {
      let decodedToken = jwtDecode(token)
      setModuleReport(ModuleType.type(decodedToken.roles))
    } else {
      if (moduleReport !== 'public_reports') {
        navigate(`${ BASE_URL }/`+moduleReport)
      }
    }
  }, [moduleReport, token])

  useEffect(() => {
    if ( (isEmpty(reportes) && moduleReport === 'public_reports') && reportes === null) {
      getDataReports()
    }
  }, [reportes, moduleReport])

  const menu_data = [
    {href: `${ BASE_URL }/`, text: 'Inicio'},
    {href: `${ BASE_URL }/`+moduleReport, text: 'Reportes', style: 'selected'},
    {href: `${ BASE_URL }/roles`, text: 'Roles'},
    {href: `${ BASE_URL }/statistics`, text: 'Estadísticas'},
    // {href: `${ BASE_URL }`, text: 'Notificaciones'},
  ]

  const headers = [
    {
      label: 'Estatus',
      name: 'status',
      //type: 'array',
      // styles: [styles.pills].join(' '),
      render: (value, index) => {
        return <span className={[styles.status, styles[`${value.replace(/\s/g, '')}`]].join(' ')}>{ value?.toUpperCase() }</span>
      }
    },
    {
      label: 'Folio',
      name: 'folio',
      //type: 'array',
      //styles: [styles.pills].join(' '),
      //render: (value) => {
      //  return <Tag>{value}</Tag>
      //}
    },
    {
      label: 'Fecha de Reporte',
      name: 'created_at',
      //type: 'array',
      //styles: [styles.pills].join(' '),
      render: (value) => {
        return moment(value).format('DD/MM/YYYY - HH:mm') ?? '-'
      }
    },
    {
      label: 'Secretaría',
      name: 'office',
      //type: 'array',
      //styles: [styles.pills].join(' '),
      //render: (value) => {
      //  return <Tag>{value}</Tag>
      //}
    },
    {
      label: 'Categoría',
      name: 'category',
      //type: 'array',
      //styles: [styles.pills].join(' '),
      //render: (value) => {
      //  return <Tag>{value}</Tag>
      //}
    },
    {
      label: 'Colonia',
      name: 'neighborhood',
      //type: 'array',
      //styles: [styles.pills].join(' '),
      //render: (value) => {
      //  return <Tag>{value}</Tag>
      //}
    },
    // {
    //    label: 'Boleta',
    //    name: null,
    //    type: 'custom',
    //    //styles: [styles.pills].join(' '),
    //    render: (item) => {
    //      console.log(item)
    //      if (item.status_raw == 'completed') {
    //        return <a href={'#'} onClick={(e) => {
    //          e.stopPropagation()
    //          showBoleta(item)
    //        }} className={styles.boletaBtn}>Ingresar boleta</a>
    //      }

    //      return ''
    //    }
    // },
  ]

  const restartData = () => {
    setReportes([])
  }

  const validateReport = (id, status) => {
    console.log('Validando reporte')
    //
    if (!isEmpty(dataUpdateForm.current)) {
      console.log(dataUpdateForm.current)

      let data = dataUpdateForm.current
      data.status = status

      updateReport(id, data)
    }

    if (status === 'rejected') {
      let data = dataUpdateForm.current
      data.status = status
      updateReport(id, data)
    }

  }

  const updateReport = (id, data) => {
    console.log('Actualizando reporte')
    console.log(data)
    api
      .updatePublicReport(token, id, data)
      .then((response) => {
        console.log('reporte actualizado')
        console.log(response)
        setTimeout(() => setCloseModal(true), 1750)
        setupModal({
          title: '',
          content: succesForm,
          actions: {}
        })
        showModal()
        restartData()
        getDataReports()
        closeReport()
      })
      .catch(error => {

        setupModal({
          title: '',
          content: errorSuccessForm,
          actions: {
            action: {
              title: 'Aceptar',
              onClick: () => {
                hideModal()
              }
            },
          }
        })
        showModal()
        console.log(error)
      })
  }

  const errorSuccessForm = (
    <div className={ [ styles.succesfull, styles.error ].join(' ') }>
      <div className={ styles.succes }>
        <FontAwesomeIcon
          icon={ solid('circle-xmark') }
          className={ [styles.icon, styles.rejected].join(' ') }
        />
        <label>¡Atención!</label>
        <p>No se pudo actualizar el reporte</p>
      </div>
    </div>
  )

  const succesForm = (
    <div className={ styles.succesfull }>
      <div className={ styles.succes }>
        <FontAwesomeIcon
          icon={ solid('circle-check') }
          className={ styles.icon }
        />
        <label>Reporte asignado con éxito</label>
        {/* <SelectButton defaultValue={'Parques y Jardines'} options={options}/> */ }
        {/* <input type="select" value={'Parques y Jardines'}/> */ }
      </div>
      <div>
        <p>Redireccionando a Reportes...</p>
      </div>
    </div>
  )

  const rejectedForm = (
    <div className={ styles.succesfull }>
      <div className={ styles.succes }>
        <FontAwesomeIcon
          icon={ solid('circle-xmark') }
          className={ [styles.icon, styles.rejected].join(' ') }
        />
        <label>Reporte rechazado con éxito</label>
        {/* <SelectButton defaultValue={'Parques y Jardines'} options={options}/> */ }
        {/* <input type="select" value={'Parques y Jardines'}/> */ }
      </div>
      <div>
        <p>Redireccionando a Reportes...</p>
      </div>
    </div>
  )

  const setDataForm = (data) => {
    dataUpdateForm.current = data
  }

  const print = (id) => {
    api.printPublicReport(token, id)
    .then((response) => {
      console.log('response del pdf')
      console.log(response)

      const content = response.headers['content-type']
      download(response.data, 'report.pdf', content)
    })
    .catch((error) => {
      console.log(error)
    });
    
  }

  const reportHandler = (item) => {
    setReport(item)
    setOpenReport(true)
  }

  const closeReport = () => {
    setOpenReport(false)
    setReport(null)
  }

  useEffect(() => {
    if (closeModal) {
      hideModal()
      destroyModalView()
      setCloseModal(false)
    }
  }, [closeModal])

  const tableActions = () => {
    const handleBuscar = (event) => {
      setBusqueda(event.target.value)
    }
  
    return (
      <>
        <div className={styles.searchInput}>
          <input
            type="text"
            placeholder="Buscar"
            value={busqueda}
            onChange={handleBuscar}
          />
          <FontAwesomeIcon
            icon={light('magnifying-glass')}
            className={styles.icon}
          />
        </div>
      </>
    )
  }
  
  return (
    <Page>
      {/*<- Content Navbar ->*/ }
      <header className={ styles.header }>
        <figure className={ styles.image }/>
        <Menu data={ menu_data }/>
        <User data={ 'Todo:data user' }/>
      </header>
      {/* <-Body Content -> */ }
      <div className={ [styles.body, openReport && !isEmpty(report) && styles.scrollContainer].filter(Boolean).join(' ') }>
        {openReport && !isEmpty(report) && (<>
          <div className={styles.printContainer}>
            <div className={ styles.return } onClick={ closeReport }>
              <FontAwesomeIcon
                    icon={regular('arrow-right')}
                    rotation={180}
                    className={styles.icon}
              />
              <p>Regresar</p>
            </div>
            <h2>{ report.folio ?? '-' }</h2>
            <div className={ styles.print }>
              <Button onClick={ () => {
                print(report.id)
              } } type="default" left-icon="print">Imprimir</Button>
            </div>
          </div>
          <div  className={ [styles.container, report.status === 'nuevo' ? styles.centered : ''].join(' ') }>
            {(report.status === 'nuevo' ? <Form data={ report } toUpdate={ setDataForm }/> : <ReportInfo data={report} toUpdate={ setDataForm }/>)}
            <div className={ styles.buttons }>
              {
                report.status === 'nuevo' && (<>
                  <Button onClick={ () => {
                    validateReport(report.id, 'rejected')
                    dataUpdateForm.current = {}
                    } } 
                    type="destructive" 
                    left-icon="xmark">
                    Rechazar
                  </Button>
                  <Button onClick={ () => {
                      if (dataUpdateForm.current.level && dataUpdateForm.current.office && dataUpdateForm.current.category) {
                        validateReport(report.id, 'accepted')
                      }
                    } } 
                    type="positive" 
                    left-icon="check">
                    Aprobar
                  </Button>
                </>)
              }
              {
                report.status !== 'nuevo' && (
                  <>
                    <Button onClick={ () => {
                      dataUpdateForm.current = {}
                      closeReport()
                      } } 
                      type="destructive" 
                      left-icon="xmark">
                      Regresar
                    </Button>
                    <Button onClick={ () => {
                      if (!isEmpty(dataUpdateForm.current)) {
                        updateReport(report.id, dataUpdateForm.current)
                      }
                      } } 
                      type="positive" 
                      left-icon="check">
                      Actualizar
                    </Button>
                  </>
                )
              }
            </div>
          </div>
          </>
        )}
        {!openReport && (<>
          <Datatable title="Reportes" headers={ headers } 
          data={
            busqueda
              ? Object.values(
                  reportes?.filter(
                    (item) =>
                      (item.folio &&
                        item.folio.toLowerCase().includes(busqueda.toLowerCase())) ||
                      (item.created_at &&
                        moment(item.created_at)
                          .format('DD/MM/YYYY')
                          .includes(busqueda.toLowerCase()))
                  )
                )
              : reportes
          }
          onClick={ reportHandler } actions={ tableActions }
          />
        </>)}
      </div>
    </Page>
  )
}

export default Index
