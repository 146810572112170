import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import {
    Menu,
    User,
    Options,
    Page,
    Datatable,
    Form, Tag,
    Button,
} from '../../components/reporteCiudadano'
import { useAtom } from 'jotai'
import api from '../../services/api'
import { BASE_URL } from '../../utilities/constants'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../contexts/modal.context'
import { useModalView } from '../../contexts/modalview.context'
import { accessToken } from '../../utilities/atoms'
// import '../../scss/Layout.scss'
import styles from './Index.module.scss'
// import Module from './components/Module'
import '../../scss/Layout.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import Boleta from './partials/Boleta'
import ReportInfo from './partials/ReportInfo'
import download from 'downloadjs'
import { ModuleType } from '../../components/enums'
import { jwtDecode } from 'jwt-decode'


const Index = () => {
    const {showModal, hideModal, setupModal} = useModal()
    const {showModalView, setupModalView, destroyModalView} = useModalView()
    const [reportes, setReportes] = useState([])
    const dataUpdateForm = useRef({})
    const dataBoletaForm = useRef({})
    const [token] = useAtom(accessToken)
    const [closeModal, setCloseModal] = useState(false)
    const [busqueda, setBusqueda] = useState('')
    const ref = useRef(null)
    const [pdfUrl, setPdfUrl] = useState(null)
    const [report, setReport] = useState(null)
    const [openReport, setOpenReport] = useState(false)
    const [moduleReport, setModuleReport] = useState('')
    const navigate = useNavigate()

    function validateBoleta(formData) {
        let errors = {}

        if (formData.hasOwnProperty('fee_condoned') && formData.fee_condoned) {
            if (!formData.hasOwnProperty('fee_amount') || formData.fee_amount.trim() === '') {
                errors.fee_amount = {error: true, message: 'Este campo es obligatorio.'}
            }
        } else {
            if (!formData.hasOwnProperty('fee_amount') || formData.fee_amount.trim() === '') {
                errors.fee_amount = {error: true, message: 'Este campo es obligatorio.'}
            }

            if (!formData.hasOwnProperty('fee_payment_date') || formData.fee_payment_date.trim() === '') {
                errors.fee_payment_date = {error: true, message: 'Fecha es obligatorio.'}
            }

            if (!formData.hasOwnProperty('fee_teller') || formData.fee_teller.trim() === '') {
                errors.fee_teller = {error: true, message: 'Este campo es obligatorio.'}
            }
        }

        if (Object.keys(errors).length === 0) {
            return true
        } else {
            ref.current.setErrorBag(errors)
            return false
        }

    }

    const getDataReports = () => {
      console.log('getDataReports')
        api
            .reports(token)
            .then((response) => {
                const reports = response.data
                // const reports = response.data.map(obj => {
                //   const { status, folio, office, category, neighborhood, created_at, process } = obj;
                //   return {
                //     status,
                //     folio,
                //     office,
                //     category,
                //     neighborhoodName: neighborhood.name,
                //     created_at,
                //     process
                //   };
                // });
                setReportes(reports)
                // const user = data.user
            })
            .catch(error => {
                // const data = error.response.data
                // const errorMessage = data.message
                console.log(error)
                // setError(true)
            })
    }

    useEffect(() => {
      if (isEmpty(moduleReport) && !isEmpty(token)) {
        let decodedToken = jwtDecode(token)
        setModuleReport(ModuleType.type(decodedToken.roles))
      } else {
        if (moduleReport !== 'reports') {
          navigate(`${ BASE_URL }/`+moduleReport)
        }
      }
    }, [moduleReport, token])

    useEffect(() => {
        if (isEmpty(reportes) && moduleReport === 'reports') {
            getDataReports()
        }
    }, [reportes, moduleReport])

    const menu_data = [
        {href: `${BASE_URL}/`, text: 'Inicio'},
        {href: `${BASE_URL}/`+moduleReport, text: 'Reportes', style: 'selected'},
        {href: `${BASE_URL}/roles`, text: 'Roles'},
        {href: `${BASE_URL}/statistics`, text: 'Estadísticas'},
        // {href: `${BASE_URL}`, text: 'Notificaciones'},
    ]

    const headers = [
        {
            label: 'Estatus',
            name: 'status',
            //type: 'array',
            // styles: [styles.pills].join(' '),
            render: (value, index) => {
                return <span
                    className={[styles.status, styles[`${value.replace(/\s/g, '')}`]].join(' ')}>{value?.toUpperCase()}</span>
            },
        },
        {
            label: 'Folio',
            name: 'folio',
            //type: 'array',
            //styles: [styles.pills].join(' '),
            //render: (value) => {
            //  return <Tag>{value}</Tag>
            //}
        },
        {
            label: 'Fecha de Reporte',
            name: 'created_at',
            //type: 'array',
            //styles: [styles.pills].join(' '),
            render: (value) => {
                return moment(value).format('DD/MM/YYYY - HH:mm') ?? '-'
            },
        },
        {
            label: 'Secretaría',
            name: 'office',
            //type: 'array',
            //styles: [styles.pills].join(' '),
            //render: (value) => {
            //  return <Tag>{value}</Tag>
            //}
        },
        {
            label: 'Categoría',
            name: 'category',
            //type: 'array',
            //styles: [styles.pills].join(' '),
            //render: (value) => {
            //  return <Tag>{value}</Tag>
            //}
        },
        {
            label: 'Colonia',
            name: 'neighborhood',
            //type: 'array',
            //styles: [styles.pills].join(' '),
            //render: (value) => {
            //  return <Tag>{value}</Tag>
            //}
        },
        {
            label: 'Boleta',
            name: null,
            type: 'custom',
            //styles: [styles.pills].join(' '),
            render: (item) => {
                console.log(item)
                if (item.status_raw == 'completed') {
                    return <a href={'#'} onClick={(e) => {
                        e.stopPropagation()
                        showBoleta(item)
                    }} className={styles.boletaBtn}>Ingresar boleta</a>
                }

                return ''
            },
        },
    ]

    const setBoletaInfo = (key, value) => {
        let data = dataBoletaForm.current
        data[key] = value
    }

    const showBoleta = (item) => {
        console.log('show')
        setupModalView({
            title: 'Reporte',
            content: (
                <Boleta onValueChange={setBoletaInfo} identifier={item.fee_identifier}
                        ref={ref}></Boleta>
            ),
            actions: {
                positive: {
                    title: 'Guardar',
                    onClick: () => {
                        console.log('aceptar')
                        console.log(dataBoletaForm.current)
                        //console.debug('consdtion')//, dataBoletaForm.current.fee_amount && dataBoletaForm.current.fee_payment_date && dataBoletaForm.current.fee_teller)
                        if (validateBoleta(dataBoletaForm.current)) {
                            dataBoletaForm.current.status = 'closed'

                            api
                                .updateReportFee(token, item.id, dataBoletaForm.current)
                                .then((response) => {
                                    console.log(response)
                                    setCloseModal(true)
                                    dataBoletaForm.current = {}
                                    // const reports = response.data
                                    // setReportes(reports)
                                    // console.log(reports)
                                    restartData()
                                })
                                .catch(error => {
                                    setupModal({
                                        title: '¡Atencion!',
                                        content: 'No se pudo actualizar el reporte',
                                        actions: {
                                            positive: {
                                                title: 'Ok',
                                                onClick: () => {
                                                    hideModal()
                                                    destroyModalView()
                                                },
                                            },
                                        },
                                    })
                                    showModal()
                                    // const data = error.response.data
                                    // const errorMessage = data.message
                                    console.log(error)
                                    // setError(true)
                                })
                        }
                    },
                },
                negative: {
                    title: 'Cancelar',
                    onClick: () => {
                        console.log('cancelar')
                        hideModal()
                        destroyModalView()
                    },
                },
            },
        })
        showModalView()
    }

    const restartData = () => {
        setReportes([])
    }

    const validateReport = (id, status) => {
        console.log('Validando reporte')
        // console.log(dataUpdateForm)
        //
        if (!isEmpty(dataUpdateForm.current)) {
            console.log(dataUpdateForm.current)

            let data = dataUpdateForm.current
            data.status = status

            updateReport(id, data)
        }

        if (status === 'rejected') {
            let data = dataUpdateForm.current
            data.status = status
            updateReport(id, data)
        }

    }

    const updateReport = (id, data) => {
        console.log('Actualizando reporte')
        console.log(data)

        const form_data = new FormData()
        form_data.append('_method', 'put')
        for (let key in data) {
            form_data.append(key, data[key])
        }

        // if (data.image) {
        //     form_data.set('image', new File([data.image], 'officer'))
        // }

        for (var key of form_data.entries()) {
            console.log(key[0] + ' = ' + key[1])
        }

        api
            .updateReport(token, id, data)
            .then((response) => {
                console.log('reporte actualizado')
                console.log(response)
                setTimeout(() => setCloseModal(true), 1750)
                setupModal({
                    title: '',
                    content: succesForm,
                    actions: {},
                })
                showModal()
                restartData()
                closeReport()
            })
            .catch(error => {
                setupModal({
                    title: '',
                    content: errorSuccessForm,
                    actions: {
                        action: {
                            title: 'Aceptar',
                            onClick: () => {
                                hideModal()
                            },
                        },
                        // positive: {
                        //   title: 'Ok',
                        //   onClick: () => {
                        //     hideModal()
                        //     destroyModalView()
                        //   }
                        // }
                    },
                })
                showModal()
                // const data = error.response.data
                // const errorMessage = data.message
                console.log(error)
                // setError(true)
            })//*/
    }

    const rejectReport = () => {
        console.log('Rechazando reporte')
        setupModal({
            title: '',
            content: rejectedForm,
            actions: {
                // positive: {
                //   title: 'Aprobar',
                //   onClick: () => {
                //     hideModal()
                //     destroyModalView()
                //   }
                // }
            },
        })
        showModal()
        setTimeout(() => setCloseModal(true), 1750)
    }

    const errorSuccessForm = (
        <div className={[styles.succesfull, styles.error].join(' ')}>
            <div className={styles.succes}>
                <FontAwesomeIcon
                    icon={solid('circle-xmark')}
                    className={[styles.icon, styles.rejected].join(' ')}
                />
                <label>¡Atención!</label>
                <p>No se pudo actualizar el reporte</p>
            </div>
        </div>
    )

    const succesForm = (
        <div className={styles.succesfull}>
            <div className={styles.succes}>
                <FontAwesomeIcon
                    icon={solid('circle-check')}
                    className={styles.icon}
                />
                <label>Reporte asignado con éxito</label>
                {/* <SelectButton defaultValue={'Parques y Jardines'} options={options}/> */}
                {/* <input type="select" value={'Parques y Jardines'}/> */}
            </div>
            <div>
                <p>Redireccionando a Reportes...</p>
            </div>
        </div>
    )

    const rejectedForm = (
        <div className={styles.succesfull}>
            <div className={styles.succes}>
                <FontAwesomeIcon
                    icon={solid('circle-xmark')}
                    className={[styles.icon, styles.rejected].join(' ')}
                />
                <label>Reporte rechazado con éxito</label>
                {/* <SelectButton defaultValue={'Parques y Jardines'} options={options}/> */}
                {/* <input type="select" value={'Parques y Jardines'}/> */}
            </div>
            <div>
                <p>Redireccionando a Reportes...</p>
            </div>
        </div>
    )

    const setDataForm = (data) => {
        dataUpdateForm.current = data
    }

    const print = (id) => {
        api.printReport(token, id)
            .then((response) => {
                console.log('response del pdf')
                console.log(response)

                const content = response.headers['content-type']
                download(response.data, 'report.pdf', content)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    const actions = (item) => {
        if (item.status === 'nuevo') {
            return {
                positive: {
                    title: 'Aprobar',
                    onClick: () => {
                        if (dataUpdateForm.current.level && dataUpdateForm.current.office && dataUpdateForm.current.category) {
                            validateReport(item.id, 'accepted')
                        } else {
                            // setRequiredValue(true)
                        }

                        // destroyModalView()
                    },
                },
                negative: {
                    title: 'Rechazar',
                    onClick: () => {
                        hideModal()
                        //rejectReport()
                        validateReport(item.id, 'rejected')
                        dataUpdateForm.current = {}
                    },
                },
            }
        } else if (!isEmpty(item.status)) {
            return {
                positive: {
                    title: 'Actualizar',
                    onClick: () => {
                        if (!isEmpty(dataUpdateForm.current)) {
                            // validateReport(item.id, 'accepted')
                            // console.log(dataUpdateForm.current)
                            updateReport(item.id, dataUpdateForm.current)
                        } else {
                            // setRequiredValue(true)
                        }
                    },
                },
                negative: {
                    title: 'Cerrar',
                    onClick: () => {
                        hideModal()
                        destroyModalView()
                        dataUpdateForm.current = {}
                    },
                },
                print: {
                    title: 'Imprimir',
                    onClick: () => {
                        console.log('Imprimiendo reporte')
                        print(item.id)
                    },
                },
            }
        } else {
            return {}
        }
    }

    const reportHandler = (item) => {
        setReport(item)
        setOpenReport(true)
        // let _action = actions(item)
        // setupModalView({
        //   title: item.folio ?? 'Reporte',
        //   content: (item.status === 'nuevo' ? <Form /*report={ 'report' }*/ data={ item } toUpdate={ setDataForm }/> : <ReportInfo data={item} toUpdate={ setDataForm }/>),
        //   actions: _action,
        // })
        // showModalView()
    }

    const closeReport = () => {
        setOpenReport(false)
        setReport(null)
    }

    useEffect(() => {
        if (closeModal) {
            hideModal()
            destroyModalView()
            setCloseModal(false)
        }
    }, [closeModal])

    const tableActions = () => {
        const handleBuscar = (event) => {
            setBusqueda(event.target.value)
        }

        return (
            <>
                <div className={styles.searchInput}>
                    <input
                        type="text"
                        placeholder="Buscar"
                        value={busqueda}
                        onChange={handleBuscar}
                    />
                    <FontAwesomeIcon
                        icon={light('magnifying-glass')}
                        className={styles.icon}
                    />
                </div>
            </>
        )
    }

    return (
        <Page>
            {/*<- Content Navbar ->*/}
            <header className={styles.header}>
                <figure className={styles.image}/>
                <Menu data={menu_data}/>
                <User data={'Todo:data user'}/>
            </header>
            {/* <-Body Content -> */}
            <div
                className={[styles.body, openReport && !isEmpty(report) && styles.scrollContainer].filter(Boolean).join(' ')}>
                {openReport && !isEmpty(report) && (<>
                        <div className={styles.printContainer}>
                            <div className={styles.return} onClick={closeReport}>
                                <FontAwesomeIcon
                                    icon={regular('arrow-right')}
                                    rotation={180}
                                    className={styles.icon}
                                />
                                <p>Regresar</p>
                            </div>
                            <h2>{report.folio ?? '-'}</h2>
                            <div className={styles.print}>
                                <Button onClick={() => {
                                    print(report.id)
                                }} type="default" left-icon="print">Imprimir</Button>
                            </div>
                        </div>
                        <div className={ [styles.container, report.status === 'nuevo' ? styles.centered : ''].join(' ') }>
                            {(report.status === 'nuevo' ?
                                <Form /*report={ 'report' }*/ data={report}
                                                              toUpdate={setDataForm}/> :
                                <ReportInfo data={report} toUpdate={setDataForm}/>)}
                            <div className={styles.buttons}>
                                {
                                    report.status === 'nuevo' && (<>
                                        <Button onClick={() => {
                                            validateReport(report.id, 'rejected')
                                            dataUpdateForm.current = {}
                                        }}
                                                type="destructive"
                                                left-icon="xmark">
                                            Rechazar
                                        </Button>
                                        <Button onClick={() => {
                                            if (dataUpdateForm.current.level && dataUpdateForm.current.office && dataUpdateForm.current.category) {
                                                validateReport(report.id, 'accepted')
                                            }
                                        }}
                                                type="positive"
                                                left-icon="check">
                                            Aprobar
                                        </Button>
                                    </>)
                                }
                                {
                                    report.status !== 'nuevo' && (
                                        <>
                                            <Button onClick={() => {
                                                dataUpdateForm.current = {}
                                                closeReport()
                                            }}
                                                    type="destructive"
                                                    left-icon="xmark">
                                                Regresar
                                            </Button>
                                            <Button onClick={() => {
                                                if (!isEmpty(dataUpdateForm.current)) {
                                                    updateReport(report.id, dataUpdateForm.current)
                                                }
                                            }}
                                                    type="positive"
                                                    left-icon="check">
                                                Actualizar
                                            </Button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </>
                )}
                {/* <input type="button" value="Presionar" onClick={modal_prueba} /> */}
                {!openReport && (<>
                    <Datatable title="Reportes" headers={headers}
                               data={
                                   busqueda
                                       ? Object.values(
                                           reportes?.filter(
                                               (item) =>
                                                   (item.folio &&
                                                       item.folio.toLowerCase().includes(busqueda.toLowerCase())) ||
                                                   (item.fee_identifier &&
                                                       item.fee_identifier
                                                           .toLowerCase()
                                                           .includes(busqueda.toLowerCase())) ||
                                                   (item.created_at &&
                                                       moment(item.created_at)
                                                           .format('DD/MM/YYYY')
                                                           .includes(busqueda.toLowerCase())),
                                           ),
                                       )
                                       : reportes
                               }
                               onClick={reportHandler} actions={tableActions}
                    />
                </>)}
            </div>
        </Page>
    )
}

export default Index
