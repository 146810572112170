import React, { useEffect, useRef, useState, useMemo } from 'react'
import { isEmpty } from 'lodash'
import api from '../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useModal } from '../../contexts/modal.context'
import { useModalView } from '../../contexts/modalview.context'
import { Form } from './'
import { Button } from './'
import { useAtom } from 'jotai'
import { accessToken } from '../../utilities/atoms'

import styles from './ComponentDatatable.module.scss'

const DataTableComponent = ({title, headers, data, onClick = null, actions = null}) => {
  const {showModal, hideModal, setupModal} = useModal()
  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const [closeModal, setCloseModal] = useState(false)
  const [token] = useAtom(accessToken)
  const [sorting, setSorting] = useState({ key: null, direction: 'asc' })

  useEffect(() => {
    if (closeModal) {
      hideModal()
      destroyModalView()
      setCloseModal(false)
    }
  }, [closeModal])

  useEffect(() => {
    console.debug('data', data)
  }, [data])

  const handleClick = (item) => {
    if (onClick != null) {
      onClick(item)
    }
  }

  const handleSort = (key) => {
    if (key == null) {
      return;
    }

    setSorting(prevSorting => {
      if (prevSorting.key === key) {
        return { key, direction: prevSorting.direction === 'asc' ? 'desc' : 'asc' }
      } else {
        return { key, direction: 'asc' }
      }
    })
  }

  const renderValue = (type, value, defaultValue, render) => {
    switch (type) {
      case 'array':
        return value.map((item, index) => {
          return render(item, index)
        })
      case 'custom':
        return render(value)
      default:
        return value ? (render ? render(value) : value) : defaultValue
    }
  }

  const sortedData = useMemo(() => {
    if (sorting.key !== null) {
      const sorted = [...data].sort((a, b) => {
        const valueA = a[sorting.key]
        const valueB = b[sorting.key]

        // Handle empty or null values
        if (valueA === null || valueA === '') return sorting.direction === 'asc' ? -1 : 1
        if (valueB === null || valueB === '') return sorting.direction === 'asc' ? 1 : -1

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return sorting.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA)
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sorting.direction === 'asc' ? valueA - valueB : valueB - valueA
        } else {
          return 0
        }
      })

      return sorted
    } else {
      return data
    }
  }, [data, sorting])

  return (
    <div className={styles.reports}>
      <div className={styles.header}>
        <div className={styles.title}>{title ?? '-'}</div>
        <div className={styles.container}>
          {actions ? actions() : null}
        </div>
      </div>
      <div className={styles.datatable}>
        <table>
          <thead>
            <tr>
              {headers
                ? headers.map((header, index) => {
                  let style = null
                  //if (index === headers.length - 1) {
                  //  style = 'lastCol'
                  //}
                  let chevronIcon = null
                  if (sorting.key === header.name) {
                    if (sorting.direction === 'asc') {
                      chevronIcon = <FontAwesomeIcon icon={solid('chevron-up')} className={styles.icon} />
                    } else if (sorting.direction === 'desc') {
                      chevronIcon = <FontAwesomeIcon icon={solid('chevron-down')} className={styles.icon} />
                    }
                  } else {
                    chevronIcon = <FontAwesomeIcon icon={solid('chevron-down')} className={styles.icon} />
                  }
                  return (
                    <th
                      className={style !== null ? styles[style] : ''}
                      key={index}
                      onClick={() => handleSort(header.name)}>
                      {header.label}
                      {header.name != null ? chevronIcon : null}
                    </th>
                  )
                })
                : null}
            </tr>
          </thead>
          <tbody>
            {!isEmpty(sortedData) ? sortedData.map((item, index) => (
              <tr key={index} onClick={() => handleClick(item)}>
                {!isEmpty(headers) ? headers.map((header, index) => {
                  return <td key={index} className={header.styles}>{renderValue(header.type, header.name ? item[header.name] : item, header.default, header.render)}</td>
                })
                  : <td>Error</td>}
              </tr>
            )) : <tr>
              <td colSpan={headers.length}>Sin datos</td>
            </tr>}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DataTableComponent