import React, { useEffect, useRef, useState } from "react";
import { useAtom } from 'jotai'
import {
  Menu, User, Page
} from "../../components/reporteCiudadano"
import { format, getMonth, getYear } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import api from '../../services/api'
import { BASE_URL } from '../../utilities/constants'
import styles from "./statistics.module.scss";
import "../../scss/Layout.scss";
import { accessToken, } from '../../utilities/atoms'
import { isEmpty } from "lodash";
import { ModuleType } from '../../components/enums'
import { jwtDecode } from 'jwt-decode'

const Statistics = () => {
  const [statistics, setStatistics] = useState({})
  const [search, setSearch] = useState('')
  const [token] = useAtom(accessToken)
  const [moduleReport, setModuleReport] = useState('')
  const [dateFilter, setDateFilter] = useState('')
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(null);

  const menu_data = [
    {href: `${ BASE_URL }/`, text: 'Inicio'},
    {href: `${ BASE_URL }/reports`, text: 'Reportes'},
    {href: `${ BASE_URL }/roles`, text: 'Roles'},
    {href: `${ BASE_URL }/Statistics`, text: 'Estadísticas', style: 'selected'},
    // {href: `${ BASE_URL }`, text: 'Notificaciones'},
  ]

  const generateOptions = () => {
    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()
    let options = [
      { id: 0, name: "Selecciona una opción", value: "" }
    ];
  
    for (let month = 1; month <= currentMonth; month++) {
      const monthName = new Date(currentYear, month - 1).toLocaleString('es-ES', { month: 'long' }).toUpperCase()
      options.push({
        id: month,
        name: `${monthName} ${currentYear}`,
        value: `${month}-${currentYear}`
      })
    }
  
    return options
  };

  const SelectComponent = ({ options, defaultOption, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false)
  
    const handleOptionChange = (index) => {
      const selected = options[index]
      setSelectedOption(selected)
      onSelect(selected)
    };
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen)
    }
  
    const selectButtonClasses = `${styles.selectButton} ${isOpen ? styles.open : ''}`
  
    return (
      <div className={selectButtonClasses} onClick={toggleDropdown}>
        <div className={styles.selectedValue}>
          <p>{selectedOption?.name}</p>
        </div>
  
        {isOpen && (
          <>
            <FontAwesomeIcon icon={regular('chevron-down')} className={styles.dropdownIcon}/>
            <ul className={styles.optionsList}>
              {options.map((option, index) => (
                <li key={option.id} onClick={() => handleOptionChange(index)}>
                  {option.name}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    )
  }

  const getPercentaje = (value, total) => {
    const result = ((value / total) * 100).toFixed(0)
    return isNaN(result) ? 0 : parseInt(result)
  }

  const data = [
    {
      title: moduleReport !== 'public_reports' ? "Reportes Ciudadanos" : "Reportes Públicos",
      cards: [
        {
          label: statistics.hasOwnProperty('recibidos') ? statistics.recibidos : 0,
          title: "recibidos",
          percentage: "100",
          className: styles.card
        },
        {
          label: statistics.hasOwnProperty('aprobados') ? statistics.aprobados : 0,
          title: "Aprobados",
          percentage: statistics.hasOwnProperty('aprobados') ? getPercentaje(statistics.aprobados, statistics.recibidos) : 0,
          className: [styles.card, styles.aprobado].join(' ')
        },
        {
          label: statistics.hasOwnProperty('rechazados') ? statistics.rechazados : 0,
          title: "Rechazados",
          percentage: statistics.hasOwnProperty('rechazados') ?  getPercentaje(statistics.rechazados, statistics.recibidos) : 0,
          className: [styles.card, styles.rechazado].join(' ')
        }
      ]
    }
  ]

  const reportsRC = {
    title: "Reportes Aprobados",
    cards: [
      {
        label: statistics.hasOwnProperty('condonados') ? statistics.condonados : 0,
        title: "Condonados",
        percentage: statistics.hasOwnProperty('condonados') ?  getPercentaje(statistics.condonados, statistics.recibidos) : 0,
        className: [styles.card, styles.condonado].join(' ')
      },
      {
        label: statistics.hasOwnProperty('solucionados') ? statistics.solucionados : 0,
        title: "Solucionados",
        percentage: statistics.hasOwnProperty('solucionados') ?  getPercentaje(statistics.solucionados, statistics.recibidos) : 0,
        className: [styles.card, styles.solucionado].join(' ')
      },
      {
        label: statistics.hasOwnProperty('total_cobrados') ? statistics.total_cobrados : 0,
        title: "Total Cobrados",
        percentage: statistics.hasOwnProperty('total_cobrados') ?  getPercentaje(statistics.total_cobrados, statistics.recibidos) : 0,
        className: [styles.card, styles.closed].join(' ')
      },
      {
        label: statistics.hasOwnProperty('nosolucionados') ? statistics.nosolucionados : 0,
        title: "No Solucionados",
        percentage: statistics.hasOwnProperty('nosolucionados') ?  getPercentaje(statistics.nosolucionados, statistics.recibidos) : 0,
        className: [styles.card, styles.nosolucionados].join(' ')
      },
      {
        label: statistics.hasOwnProperty('ignorados') ? statistics.ignorados : 0,
        title: "No Atendidos",
        percentage: statistics.hasOwnProperty('ignorados') ?  getPercentaje(statistics.ignorados, statistics.recibidos) : 0,
        className: [styles.card, styles.ignorados].join(' ')
      }
    ]
  }

  const reportsSP = [
    {
      title: "Reportes Aprobados",
      cards: [
        {
          label: statistics.hasOwnProperty('antendidos') ? statistics.antendidos : 0,
          title: "Antendidos",
          percentage: statistics.hasOwnProperty('antendidos') ?  getPercentaje(statistics.antendidos, statistics.recibidos) : 0,
          className: [styles.card, styles.condonado].join(' ')
        },
        {
          label: statistics.hasOwnProperty('solucionados') ? statistics.solucionados : 0,
          title: "Solucionados",
          percentage: statistics.hasOwnProperty('solucionados') ?  getPercentaje(statistics.solucionados, statistics.recibidos) : 0,
          className: [styles.card, styles.solucionado].join(' ')
        },
        {
          label: statistics.hasOwnProperty('nosolucionados') ? statistics.nosolucionados : 0,
          title: "No Solucionados",
          percentage: statistics.hasOwnProperty('nosolucionados') ?  getPercentaje(statistics.nosolucionados, statistics.recibidos) : 0,
          className: [styles.card, styles.nosolucionados].join(' ')
        },
      ]
    },
    {
      title: "Reportes Solucionados",
      cards: [
        {
          label: statistics.hasOwnProperty('totalSolucionadosAP') ? statistics.totalSolucionadosAP : 0,
          title: "Alumbrado publico",
          percentage: statistics.hasOwnProperty('totalSolucionadosAP') ?  getPercentaje(statistics.totalSolucionadosAP, statistics.solucionados) : 0,
          className: [styles.card, styles.solucionado].join(' ')
        },
        {
          label: statistics.hasOwnProperty('totalSolucionadosBC') ? statistics.totalSolucionadosBC : 0,
          title: "Basurero Clandestino",
          percentage: statistics.hasOwnProperty('totalSolucionadosBC') ?  getPercentaje(statistics.totalSolucionadosBC, statistics.solucionados) : 0,
          className: [styles.card, styles.solucionado].join(' ')
        },
        {
          label: statistics.hasOwnProperty('totalSolucionadosRB') ? statistics.totalSolucionadosRB : 0,
          title: "Recolección de Basura",
          percentage: statistics.hasOwnProperty('totalSolucionadosRB') ?  getPercentaje(statistics.totalSolucionadosRB, statistics.solucionados) : 0,
          className: [styles.card, styles.solucionado].join(' ')
        },
      ]
    }
  ]


  
  const cobros = [
    {
      title: "Cobros",
      cards: [
        {
          label: "$" + (parseFloat(statistics?.total_cobros?.total) || 0).toLocaleString(),
          title: "Reportes",
          percentage: "100",
          className: styles.card
        },
        {
          label: "$" + (parseFloat(statistics?.total_cobros?.condonados) || 0).toLocaleString(),
          title: "Condonados",
          percentage: getPercentaje(
            parseFloat(statistics?.total_cobros?.condonados) || 0,
            parseFloat(statistics?.total_cobros?.total) || 0
          ),
          className: [styles.card, styles.condonado].join(' ')
        },
        {
          label: "$" + (parseFloat(statistics?.total_cobros?.solucionados) || 0).toLocaleString(),
          title: "Solucionados",
          percentage: getPercentaje(
            parseFloat(statistics?.total_cobros?.solucionados) || 0,
            parseFloat(statistics?.total_cobros?.total) || 0
          ),
          className: [styles.card, styles.solucionado].join(' ')
        },
      ]      
    }
  ]

  const renderCards = (group, index) => (
    <div key={index} className={styles.content}>
      <div className={styles.item}>
        <label className={styles.label}>{group.title}</label>
        <div className={styles.cards}>
          {group.cards.map((card, cardIndex) => (
            <div key={cardIndex} className={card.className}>
              <label className={styles.label}>{card.label}</label>
              <p className={styles.titles}>{card.title}</p>
              <h4 className={styles.percentage}>{card.percentage + '%'}</h4>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const _search = [
    { id: 1, name: 'Por Mes', value: 'mes' },
    { id: 2, name: 'Por Dia', value: 'dia' },
    { id: 3, name: 'Por Año', value: 'anio' },
  ];

  const getCurrentMonthYear = () => {
    const currentDate = new Date()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    return `${month}-${year}`
  };

  const getDataStatistics = () => {
    const data = {
      "filter_type": !isEmpty(search) ? search : getCurrentMonthYear()
    }
    console.log('getDataStatistics')
    console.log(data)
    api
      .statistics(moduleReport, token, data)
      .then((response) => {
        const statistics = response.data
        console.log(statistics)
        setStatistics(statistics)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const searchSelected = (selectedOption) => {
    setDateFilter(selectedOption.name)
    setSearch(selectedOption.value ?? '')
  }

  const renderRC = (data, reportsRC, cobros) => {
    const combinedData = data.concat(reportsRC)
    return combinedData.concat(cobros).map(renderCards)
  }
  
  const renderRSP = (data, reports) => {
    const combinedData = data.concat(reports)
    return combinedData.map(renderCards)
  }

  useEffect(() => {
    const newOptions = generateOptions()
    const optionDefault = newOptions[newOptions.length - 1]
    setDateFilter(optionDefault.name)
    setOptions(newOptions)
    setSelectedOption(optionDefault)
  }, []);

  useEffect(() => {
    if (isEmpty(moduleReport) && !isEmpty(token)) {
      let decodedToken = jwtDecode(token)
      setModuleReport(ModuleType.type(decodedToken.roles))
    }
  }, [moduleReport, token])

  useEffect(() => {
    console.log('effect search')
    console.log([search]?.name)
    console.log(search)
  }, [search])

  useEffect(() => {
    if (isEmpty(statistics) && !isEmpty(moduleReport)) {
      getDataStatistics()
    }
  }, [statistics,moduleReport])

  useEffect(() => {
    if (!isEmpty(search) && !isEmpty(moduleReport)) {
      getDataStatistics()
    } else if (isEmpty(search)) {
      const currentMontYear = getCurrentMonthYear()
      const selectedOption = options.find(option => option.value === currentMontYear)
      if (selectedOption) {
          searchSelected(selectedOption.value);
          setSelectedOption(selectedOption);
          setDateFilter(selectedOption.name);
      }
    }
  }, [search])

  return (
    <Page>
      {/*<- Content Navbar ->*/}
      <header className={ styles.header }>
        <figure className={ styles.image }/>
        <Menu data={ menu_data }/>
        <User data={ 'Todo:data user' }/>
      </header>
      {/* <-Body Content -> */}
      <div className={styles.body}>
        <div>
          <div className={styles.headerContainer}>
            <div className={styles.title}>{'Estadísticas'}</div>
            <div className={styles.container}>
              <div className={styles.selectInput}>
              <SelectComponent
                options={options}
                onSelect={searchSelected}
              />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={ styles.titleContent }>
            <label className={styles.label}>- {dateFilter} -</label>
          </div>
          
          {moduleReport === 'reports' ? renderRC(data, reportsRC, cobros) : renderRSP(data,reportsSP)}
        </div>
      </div>
    </Page>
  );
};

export default Statistics;
