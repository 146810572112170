import React, {useRef, useEffect} from 'react'

import styles from './ComponentModalView.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useModalView } from '../../contexts/modalview.context'
import { DestructiveButton, PositiveButton } from './ReporteCiudadano.styled'
import * as Styled from './ComponentModal.styled'
import { Button } from './'

const ComponentModalView = ({show, title, cancelable, children, positiveButtonTitle,
  negativeButtonTitle, buttonType, onClose,
  onAccept, onDestroy, onDefault, defaultButtonTitle, actionButtonTitle, destructiveButtonTitle, onPrint, onPrintTitle}) => {

  const {destroyModalView} = useModalView()
  const modalViewContainerRef = useRef(null)

  const dismiss = () => {
    destroyModalView()
  }

  const handleClickOutside = (event) => {
    if (modalViewContainerRef.current && !modalViewContainerRef.current.contains(event.target)) {
      dismiss()
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show])

  return (
    <div className={ [styles.modalView, !show && styles.hidden].join(' ') }>
      <div className={ styles.modalViewContainer } ref={modalViewContainerRef}>
        <div className={ styles.modalViewHeader }>
          { onPrintTitle && (
            <div className={ styles.printContainer }>
                <div className={ styles.return } onClick={ dismiss }>
                  <FontAwesomeIcon
                        icon={regular('arrow-right')}
                        rotation={180}
                        className={styles.icon}
                  />
                  <p>Regresar</p>
                </div>
                <h2>{ title }</h2>
                { cancelable && (
                <div className={ styles.print }>
                  <Button onClick={ onPrint } type="default" left-icon="print">Imprimir</Button>
                </div>
              ) }
            </div>
          )}

          { !onPrintTitle && (<h2>{ title }</h2>)}

          { !onPrintTitle && cancelable && (
              <div className={ styles.close } onClick={ dismiss }>
                <FontAwesomeIcon icon={ light('xmark') } size={'2xl'}/>
              </div>
            )
          } 

          {/* <h2>{ title }</h2>
          { cancelable && (
            <div className={ styles.close } onClick={ dismiss }>
              <FontAwesomeIcon icon={ light('xmark') } size={'2xl'}/>
            </div>
          ) } */}
        </div>
        { children }
        <Styled.ModalFooter>
          { negativeButtonTitle && positiveButtonTitle ? <div className={ styles.buttonsGroupRight }>
            <Button onClick={ onClose } type="destructive" left-icon="xmark">{ negativeButtonTitle }</Button>
            <Button onClick={ onAccept } type="positive" left-icon="check">{ positiveButtonTitle }</Button>
          </div> : ''}

          {positiveButtonTitle && !negativeButtonTitle ? <div>
            <Button onClick={ onAccept } type="default">{ positiveButtonTitle }</Button>
          </div> : ''}

          {/* {negativeButtonTitle && !positiveButtonTitle ? <div>
            <Button onClick={ onClose } type="default">{ negativeButtonTitle }</Button>
          </div> : ''} */}

          {
            defaultButtonTitle && actionButtonTitle ? (
              <>
                {destructiveButtonTitle && (
                  <div>
                    <Button onClick={onDestroy} type="delete">
                      {destructiveButtonTitle}
                    </Button>
                  </div>
                )}

                <div className={styles.buttonsGroupRight}>
                  <Button onClick={onDefault} type="default">
                    {defaultButtonTitle}
                  </Button>
                  <Button onClick={onAccept} type="action">
                    {actionButtonTitle}
                  </Button>
                </div>
              </>
            ) : null
          }
          {/* { negativeButtonTitle &&
                        <Button to={ '' } type="destructive" left-icon="xmark">Cancelar</Button> }
          { positiveButtonTitle &&
            <Button to={ '' } type="positive" left-icon="check">Ver Historial</Button> } */}

          {/* { negativeButtonTitle &&
          <DestructiveButton type={ buttonType }
                              onClick={ onClose }>{ negativeButtonTitle ?? 'Cancelar' }</DestructiveButton> }
          { positiveButtonTitle &&
            <PositiveButton type={ buttonType } onClick={ onAccept }>{ positiveButtonTitle }</PositiveButton> } */}
        </Styled.ModalFooter>
      </div>
    </div>
  )
}

export default ComponentModalView