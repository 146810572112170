import styled from 'styled-components'
import { colors, settings } from './_config.styled'

const AbstractButton = styled.button`
  border: none;
  border-radius: ${ settings.borderRadius };
  color: white;
  cursor: pointer;
   margin: 20px;
  font-weight: 600;
  font-size: ${ props => props.type === 'small' ? 11 * settings.scale : 16 * settings.scale }px;
  padding: ${ 12 * settings.scale }px ${ 24 * settings.scale }px;
  text-align: center;
  transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
`

export const DestructiveButton = styled(AbstractButton)`
  background-color: ${ colors.destructive };
`

export const PositiveButton = styled(AbstractButton)`
  background-color: ${ colors.neutral };
`