import React, { useEffect, useRef, useState } from "react";
import {
  Menu,
  User,
  Options,
  Page,
  Datatable,
  Form,
} from "../../components/reporteCiudadano";
import { BASE_URL } from '../../utilities/constants'
// import '../../scss/Layout.scss'
import styles from "./Dashboard.module.scss";
import Module from './components/Module'
import "../../scss/Layout.scss";
import { useAtom } from 'jotai'
import { ModuleType } from '../../components/enums'
import {
  accessToken
} from '../../utilities/atoms'
import { jwtDecode } from 'jwt-decode';
import { isEmpty } from 'lodash'

const Dashboard = () => {
  const [token,] = useAtom(accessToken)
  const [moduleReport, setModuleReport] = useState('')
  useEffect(() => {
    if (isEmpty(moduleReport) && !isEmpty(token)) {
      let decodedToken = jwtDecode(token)
      setModuleReport(ModuleType.type(decodedToken.roles))
    }
  }, [moduleReport, token])

  return (
    <Page>
      {/*<- Content Navbar ->*/}
      <header className={styles.header}>
        <figure className={styles.image} />
        <User data={"Todo:data user"} />
      </header>
      {/* <-Body Content -> */}
      <div className={styles.body}>
        <Options>
          <Module
            label={"Reportes"}
            icon={"reports"}
            route={`${BASE_URL}/`+moduleReport}
          />
          <Module
            label={"Roles"}
            icon={"roles"}
            route={`${BASE_URL}/roles`}
          />
          <Module
            label={"Estadísticas"}
            icon={"statistics"}
            route={`${BASE_URL}/statistics`}
          />
          {/* <Module
            label={"Notificaciones"}
            icon={"notifications"}
            disabled={"disabled"}
            route={`${BASE_URL}`}
          /> */}
        </Options>
      </div>
    </Page>
  );
};

export default Dashboard;
