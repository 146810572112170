/*----- Colors -----*/
export const colors = {
  call_to_action: `#2CC682`,
  call_to_action_secondary: `#22B876`,

  neutral: `#16A34A`,
  neutral_secondary: `#0F1955`,

  destructive: `#DC2626`,
  destructive_secondary: `#d03737`,

  disabled: `#64748B`,
  text_disabled: `#94A3B8`,

  tertiary: `#F4F7FC`,
  body: `#6E80A9`,
}

/*----- Settings -----*/
const scale = 1.50
export const settings = {
  scale,
  borderRadius: `${ 4 * scale }px`
}