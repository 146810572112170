import React, { useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import {
  Menu,
  User,
  Options,
  Page,
  Datatable,
  Form, Tag, Button,
} from '../../components/reporteCiudadano'
import { BASE_URL } from '../../utilities/constants'
// import '../../scss/Layout.scss'
import styles from './Index.module.scss'
// import Module from './components/Module'
import '../../scss/Layout.scss'
import { useModal } from '../../contexts/modal.context'
import { useModalView } from '../../contexts/modalview.context'
import api from '../../services/api'
import { useAtom } from 'jotai'
import { accessToken } from '../../utilities/atoms'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import CreateUser from './partials/CreateUser'

const Index = () => {
  const {showModal, hideModal, setupModal} = useModal()
  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const [token] = useAtom(accessToken)
  const dataCreateForm = useRef({})
  const [busqueda, setBusqueda] = useState('')
  const [closeModal, setCloseModal] = useState(false)
  const [errors, setErrors] = useState({})
  const ref = useRef(null);
  // const error = useRef(false)
  useEffect(() => {
    if (closeModal) {
      hideModal()
      destroyModalView()
      setCloseModal(false)
    }
  }, [closeModal])

  const menu_data = [
    {href: `${ BASE_URL }/`, text: 'Inicio'},
    {href: `${ BASE_URL }/reports`, text: 'Reportes'},
    {href: `${ BASE_URL }/roles`, text: 'Roles', style: 'selected'},
    {href: `${ BASE_URL }/statistics`, text: 'Estadísticas'},
    // {href: `${ BASE_URL }`, text: 'Notificaciones'},
  ]

  const headers = [
    {
      label: 'Nombre',
      name: 'name',
    },
    //{
    //  label: 'Secretaría',
    //  name: '',
    //},
    {
      label: 'Rol',
      name: 'roles',
      type: 'array',
      styles: [styles.pills].join(' '),
      render: (value, index) => {
        return <Tag key={'tag' + index} className={[styles.tag, styles[`${value}`]]}>{ value }</Tag>
      }
    },
    {
      label: 'Reportes',
      name: 'reports',
      default: 0,
    },
    {
      label: 'Sin Atender',
      name: 'reports',
      default: 0,
    },
    {
      label: 'Solucionados',
      name: 'reports',
      default: 0,
    },
    {
      label: 'No Solucionados',
      name: 'reports',
      default: 0,
    },
    {
      label: 'Condonados',
      name: 'reports',
      default: 0,
    },
  ]

  const [users, setUsers] = useState([])
  const getAdminUsers = () => {
    api
      .adminUsers(token)
      .then((response) => {
        const users = response.data
        setUsers(users)
        console.log(users)
      })
      .catch(error => {
        // const data = error.response.data
        // const errorMessage = data.message
        console.log(error)
        // setError(true)
      })
  }

  useEffect(() => {
    if (isEmpty(users)) {
      getAdminUsers()
    }
  }, [users])

  function validateData(formData) {
    console.log('validating Data')
    let errors = {};
    //Type validation 
    let type = formData.hasOwnProperty('id') ? 'edit' : 'create'

    //email
    if (type !== 'edit' && (!formData.hasOwnProperty('email') || formData.email.trim() === '')) {
      errors.email = { error: true, message: 'El campo Email es obligatorio.' };
    } else if (formData.hasOwnProperty('email')) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      if (!emailRegex.test(formData.email)) {
        errors.email = { error: true, message: 'El campo Email no es válido.' };
      }
    }

    //password
    if (type !== 'edit' && (!formData.hasOwnProperty('password') || formData.password.trim() === '')) {
      errors.password = { error: true, message: 'El campo Contraseña es obligatorio.' };
    } else if (formData.hasOwnProperty('password')) {
      if (formData.password.length < 8 && !isEmpty(formData.password)) {
        errors.password = { error: true, message: 'El campo Contraseña debe tener al menos 8 caracteres.' };
      }
    }

    //password_confirmation
    if (type !== 'edit' && (!formData.hasOwnProperty('password_confirmation') || formData.password_confirmation.trim() === '')) {
      errors.password_confirmation = { error: true, message: 'El campo Confirmación de Contraseña es obligatorio.' };
    } else if (formData.password_confirmation !== formData.password) {
      errors.password_confirmation = { error: true, message: 'La confirmación de contraseña no coincide.' };
    }

    //name
    if (!formData.hasOwnProperty('name') ||  formData.name.trim() === '') {
      errors.name = { error: true, message: 'El campo Nombre es obligatorio.' };
    }

    //lastname
    if (!formData.hasOwnProperty('lastname') || formData.lastname.trim() === '') {
      errors.lastname = { error: true, message: 'El campo Apellido es obligatorio.' };
    }

    // //second_lastname
    // if (!formData.hasOwnProperty('second_lastname') || formData.second_lastname.trim() === '') {
    //   errors.secondlastname = { error: true, message: 'El campo Segundo Apellido es obligatorio.' };
    // }

    //identifier
    if (!formData.hasOwnProperty('identifier') || formData.identifier.trim() === '') {
      errors.identifier = { error: true, message: 'El campo Identificador es obligatorio.' };
    }

    //phone
    if ((!formData.hasOwnProperty('phone') || formData.phone.trim() === '') ) {
      errors.phone = { error: true, message: 'El campo Teléfono es obligatorio.' };
    } else if (formData.phone.trim().length !== 10) {
      errors.phone = { error: true, message: 'El campo Teléfono debe tener 10 dígitos.' };
    }

    //role
    if (type !== 'edit' && (!formData.hasOwnProperty('role') || formData.role.trim() === '')) {
      errors.role = { error: true, message: 'El campo Rol es obligatorio.' };
    }

    ref.current.setErrorBag(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
    // for (let key in data) {
    //   if (!data[key]) {
    //     return false;
    //   }
    // }
    // return true;
  }

  const sendUpdate = (id, message, dataUpdate) => {
    api
    .updateUser(token, id, dataUpdate)
    .then((response) => {
      // const users = response.data
      // setUsers(users)
      setTimeout(() => setCloseModal(true), 1750)
        setupModal({
          title: '',
          content: updated(message),
          actions: {}
        })
      showModal()
      destroyModalView()
      getAdminUsers()
      dataCreateForm.current = {}
    })
    .catch(error => {
      // const data = error.response.data
      // const errorMessage = data.message
      console.log(error)
      dataCreateForm.current = {}
      // setError(true)
    })
  }

  const updateUser = (message) => {
    let dataUpdate = dataCreateForm.current
    let id = dataUpdate.id
    if ( validateData(dataUpdate) ) {
      sendUpdate(id, message, dataUpdate)
    }
  }

  const deleteUser = (message) => {
    let dataUpdate = dataCreateForm.current
    let id = dataUpdate.id
    sendUpdate(id, message, dataUpdate)
  }

  const createNewUser = () => {

    if ( validateData(dataCreateForm.current) ) {
      api
      .createUser(token, dataCreateForm.current)
      .then((response) => {
        // const users = response.data
        // setUsers(users)
        setTimeout(() => setCloseModal(true), 1750)
          setupModal({
            title: '',
            content: updated({
              icon: 'circle-check',
              message: 'Usuario creado con éxito',
              redirect: 'Redireccionando a Usuarios...'
            }),
            actions: {}
          })
        showModal()
        console.log(response)
        getAdminUsers()
        dataCreateForm.current = {}
      })
      .catch(error => {
        // const data = error.response.data
        // const errorMessage = data.message
        console.log(error)
        dataCreateForm.current = {}
        // setError(true)
      })
    } else {
      // console.log('Faltan datos')
      // dataCreateForm.current = {}
    }
  }

  const setDataForm = (data) => {
    dataCreateForm.current = data
  }

  const addUser = () => {
    // dataCreateForm.current = {}
    console.log(dataCreateForm.current)
    setupModalView({
      title: 'Agregar nuevo usuario',
      content: (<CreateUser toCreate={ setDataForm } type={ 'create' } ref={ref}/>),
      actions: {
        action: {
          title: 'Guardar',
          onClick: () => {
            console.log('Guardar')
            createNewUser()
            // destroyModalView()
          }
        },
        default: {
          title: 'Cancelar',
          onClick: () => {
            destroyModalView()
            dataCreateForm.current = {}
            //rejectReport()
            //dataUpdateForm.current = {}
          }
        }
      }
    })
    showModalView()
  }

  const userHandler = (item) => {
    console.log('userHandler')
    console.log(item)
    // let _action = actions(item)
    setupModalView({
      title: 'Editar usuario',
      content: (<CreateUser data={ item } toEdit={ setDataForm } type={'edit'} ref={ref}/>),
      actions: {
        action: {
          title: 'Guardar',
          onClick: () => {
            console.log('Actualizar')
            let message = {
              icon: 'circle-check',
              message: 'Usuario actualizado con éxito',
              redirect: 'Redireccionando a Usuarios...'
            }
            updateUser(message)
            // destroyModalView()
          }
        },
        default: {
          title: 'Cancelar',
          onClick: () => {
            destroyModalView()
            dataCreateForm.current = {}
            //rejectReport()
            //dataUpdateForm.current = {}
          }
        },
        destructive: {
          title: 'Eliminar Usuario',
          onClick: () => {
            dataCreateForm.current.roles = ['user']
            let message = {
              icon: 'circle-check',
              message: 'Usuario eliminado con éxito',
              redirect: 'Redireccionando a Usuarios...'
            }
            deleteUser(message)
            // updateUser(message)
            // destroyModalView()
          }
        }
      }
    })
    showModalView()
  }

  const Icons = {
    icon:(name) => {
      if (name === 'circle-check') {
        return solid('circle-check')
      }
    }
  }

  const updated = ($data) => {
    let name = $data.icon
    return (
      <div className={ styles.succesfull }>
        <div className={ styles.succes }>
          <FontAwesomeIcon
            icon={ Icons.icon(name) }
            className={ styles.icon }
          />
          <label>{$data.message}</label>
        </div>
        <div>
          <p>{$data.redirect}</p>
        </div>
      </div>
    )
  }

  const tableActions = () => {
    console.log('tableActions')
    const handleBuscar = (event) => {
      setBusqueda(event.target.value)
    }
  
    return (
      <>
        <div className={styles.searchInput}>
          <input
            type="text"
            placeholder="Buscar"
            value={busqueda}
            onChange={handleBuscar}
          />
          <FontAwesomeIcon
            icon={light('magnifying-glass')}
            className={styles.icon}
          />
        </div>
        <Button onClick={ addUser } type="default">Agregar Usuario</Button>
      </>
    )
  }

  return (
    <Page>
      {/*<- Content Navbar ->*/ }
      <header className={ styles.header }>
        <figure className={ styles.image }/>
        <Menu data={ menu_data }/>
        <User data={ 'Todo:data user' }/>
      </header>
      {/* <-Body Content -> */ }
      <div className={ styles.body }>
        {/* <input type="button" value="Presionar" onClick={modal_prueba} /> */ }
        <Datatable title="Roles" headers={ headers } 
          data={ busqueda ? 
            Object.values(users?.filter((item) => item.name && item.name.toLowerCase().includes(busqueda.toLowerCase()))) : users
            }
          onClick={ userHandler } 
          actions={ tableActions }
        />
      </div>
    </Page>
  )
}

export default Index
