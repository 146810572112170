import React from 'react'
import styles from './ComponentOptions.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, thin, regular } from '@fortawesome/fontawesome-svg-core/import.macro'

const ComponentOptions = ({ children }) => {

  return (
    <>
     <div className={styles.options}>
        <h1 className={styles.title}>Opciones</h1>
        <p className={styles.description}>Seleccione la opción deseada</p>
        <hr className={styles.divider}></hr>
        <div className={styles.items}>
          {children}
          {/* <div className={styles.itemContainer}>
            <div className={styles.item}>
              <FontAwesomeIcon icon={thin('tablet')} flip="vertical"  className={ styles.icon }/>
            </div>
            <p>Reportes</p>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <FontAwesomeIcon icon={light('user-tie-hair')} className={ styles.icon }/>
            </div>
            <p>Roles</p>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <FontAwesomeIcon icon={light('chart-line')} className={ styles.icon }/>
            </div>
            <p>Estadísticas</p>
          </div>
          <div className={styles.itemContainer}>
            <div className={styles.item}>
              <FontAwesomeIcon icon={light('message')} className={ styles.icon }/>
            </div>
            <p>Notificaciones</p>
          </div> */}
        </div>
      </div>
    </>
    )
}

export default ComponentOptions
