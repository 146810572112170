import React from 'react'
import { Link } from 'react-router-dom'
import './ButtonComponent.scss'
import { ReactComponent as PrintIcon } from '../../assets/images/print.svg'

const ButtonComponent = (props) => {

  const icon = (icon) => {
    return (
      <span className="icon-wrapper">
        {icon === 'print' && <PrintIcon className="print" />}
        {icon !== 'print' && <svg className="icon"> 
          <use xlinkHref={`#${icon}`}/>
        </svg>}
      </span>
    )
  }

  const button = (
    <span className={[`button-style-${props.type}`].join(' ')}>
      {props['left-icon'] && icon(props['left-icon'])}
      <span className="label">{props.children}</span>
      {props['right-icon'] && icon(props['right-icon'])}
    </span>
  )

  return props.to ? (
    <ComponentButtonWrapperRoute {...props}>
      {button}
    </ComponentButtonWrapperRoute>
  ) : (<div className="button" onClick={props.onClick}>{button}</div>)
}

const ComponentButtonWrapperRoute = ({ to, children}) => {
  return (
    <Link to={to} className="button">
      {children}
    </Link>
  )
}

export default ButtonComponent
