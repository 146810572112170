import React, { createRef, useEffect, useState } from "react"
// import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { Routes, Route } from "react-router-dom"
import Dashboard from "./modules/dashboard/Dashboard"
import Login from "./modules/dashboard/Login"
import DeleteAccountInstructions from "./modules/static/DeleteAccountInstructions"
import ProtectedRoute from "./components/navigation/PrivateRoute"
import ReportsRoutes from "./modules/reports/routes"
import RolesRoutes from "./modules/roles/routes"
import StatisticsRoutes from "./modules/statistics/routes"
import PublicServicesReports from "./modules/publicos/routes"
import "./App.css";

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/login"
        element={ <Login /> }
      />
      <Route
        exact
        path="/delete-account"
        element={ <DeleteAccountInstructions /> }
      />
      <Route
        exact
        path="/"
        element={<ProtectedRoute component={<Dashboard />} />}
      />
      {ReportsRoutes}
      {RolesRoutes}
      {StatisticsRoutes}
      {PublicServicesReports}
    </Routes>
  );
}

export default App;
