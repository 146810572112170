import styled from 'styled-components'
import { colors, settings } from './_config.styled'

export const Modal = styled.div`
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
`

export const ModalContent = styled.div`
  background-color: white;
  border-radius: ${ settings.borderRadius };
  width: ${320 * settings.scale}px;
  padding: ${18 * settings.scale}px;
  // border: 2px solid #68d028a4;
`

export const ModalHeader = styled.div`
  
`

export const ModalTitle = styled.h4`
  font-size: ${16 * settings.scale}px;
  margin: 0;
  text-align: center;
`

export const ModalBody = styled.div`
  color: ${colors.body};
  font-weight: 400;
  font-feature-settings: 'cv11' on;
  font-size: ${12 * settings.scale}px;
  line-height: 150%;
`

export const ModalFooter = styled.div`
  display: flex;
  background-color: #FFFFFF;
  border-top: 1px solid #CBD5E1;
  // background-color: #F1F5F9;
  gap: ${28 * settings.scale}px;
  width: 100%;
  justify-content: center;
  padding: 18px;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`
export const Footer = styled.div`
  display: flex;
  background-color: #FFFFFF;
  // border-top: 1px solid #CBD5E1;
  // background-color: #F1F5F9;
  gap: ${28 * settings.scale}px;
  width: 100%;
  justify-content: center;
  padding: 18px;
  box-sizing: border-box;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`