import React, { useEffect, useState } from "react"
import { isEmpty } from 'lodash'
import styles from "./ComponentForm.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  light,
  thin,
  regular,
} from "@fortawesome/fontawesome-svg-core/import.macro"
import { SelectButton } from './'
import { CheckButton, Map, Button } from './'
import moment from 'moment'
import { ReactComponent as LocationDot } from '../../assets/images/location-dot.svg'
import { useModalView } from '../../contexts/modalview.context'
import api from '../../services/api'
import { useAtom } from "jotai"
import { accessToken } from "../../utilities/atoms"

const ComponentForm = ({data, toUpdate, requiredValues, report}) => {

  const [selectedLevel, setSelectedLevel] = useState(data.level_raw)
  const [secretaria, setSecretaria] = useState(null)
  const [categoria, setCategoria] = useState(null)
  const [mapOpened, setMapOpened] = useState(false)
  const [openPhoto, setOpenPhoto] = useState(false)
  const {showModalView, setupModalView, destroyModalView} = useModalView()
  const [token] = useAtom(accessToken)
  const [requiredValue, setRequiredValue] = useState(requiredValues)

  const [catalogOffices, setCatalogOffices] = useState(null)
  const [catalogCategories, setCatalogCategories] = useState(null)
  const levels = [{name:'Leve', value: 'low'}, {name:'Moderado', value: 'medium'}, {name:'Grave', value: 'high'}]

  const setLevel = (level) => {
    setSelectedLevel(level)
    console.log(level)
  }

  const getOffices = () => {
    setCatalogCategories(null)
    api.catalogOffices(token)
      .then((response) => {
        setCatalogOffices(response.data)
        console.log(response.data)
      })
  }

  const getCategories = (id) => {
    api.catalogCategories(token, id)
      .then((response) => {
        setCatalogCategories(response.data)
        console.log(response.data)
      })
  }

  const secretariaSelected = (index) => {
    setSecretaria(catalogOffices[index])
    getCategories(catalogOffices[index].id)
  }

  const categoriaSelected = (index) => {
    setCategoria(catalogCategories[index])
  }

  const openMap = () => {
    // event.preventDefault()
    console.log('Openmap')
    if (!mapOpened) {
      setMapOpened(true)
    }
  }

  const closeItems = () => {
    setMapOpened(false)
    setOpenPhoto(false)
  }

  const openPhotoView = () => {
    setOpenPhoto(true)
  }

  useEffect(() => {
    console.log('test', selectedLevel)
  }, [selectedLevel])

  useEffect(() => {
    getOffices()

    if (data.category_id != null) {
      getCategories(data.category_id)
    }
  }, [])

  useEffect(() => {
    if (catalogOffices != null && data.office_id != null) {
      const findValue = (element) => {
        return element.id == data.office_id
      }
      let index = catalogOffices.findIndex(findValue)
      setSecretaria(catalogOffices[index])
    }
  }, [catalogOffices])

  useEffect(() => {
    if (catalogCategories != null && data.category_id != null) {
      const findValue = (element) => {
        return element.id == data.category_id
      }
      let index = catalogCategories.findIndex(findValue)
      setCategoria(catalogCategories[index])
    }
  }, [catalogCategories])

  const mapLocation = (
    <div className={styles.mapContainer}>
      <Map
        zoom={20}
        lat={data.lat}
        lng={data.lng}
      />
      <Button onClick={closeItems} type="default">Cerrar Mapa</Button>
    </div>
  )

  const photoView = (
    <div className={styles.mapContainer}>
      <div className={styles.photoView} style={{
        backgroundImage: `url(${
          data.picture ?? ''
        })`
      }}>
      </div>
      <Button onClick={closeItems} type="default">Cerrar Foto</Button>
    </div>
  )

  const formPriority = (
    <div>
      <div className={styles.formGroup}>
        <label>Departamento</label>
        <SelectButton defaultValue={catalogOffices ? catalogOffices[0] : 'Cargando secretarías...'}
                      options={catalogOffices} label={'name'} value={'id'}/>
      </div>
      <div className={styles.formGroup}>
        <p>Para continuar necesitas asignarle un nivel de prioridad a este reporte.</p>
      </div>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        {levels.map(option => (
          <CheckButton selected={selectedLevel} key={option.name} type={option.value}
                       onClick={(selected) => setSelectedLevel(selected)}/>
        ))}
      </div>
    </div>
  )

  const formReport = (
    <>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item}>
          <label>Folio</label>
          <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label>Fecha</label>
          <input type="text" id="fecha" value={data.created_at ?? '-'} readOnly/>
        </div>
      </div>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item}>
          <label>Prioridad</label>
          {/*<SelectButton defaultValue={ data.office } options={optionsSec} selectedVal={ secretariaSelected }/>*/}
          {/* <input type="text" id="folio" value="Tránsito y Vialidad" readOnly /> */}
        </div>
        <div className={styles.item}>
          <label>Secretaría</label>
          {/*<SelectButton defaultValue={ data.office } options={optionsSec} selectedVal={ secretariaSelected }/>*/}
        </div>
        <div className={styles.item}>
          <label>Categoría</label>
          {/*<SelectButton defaultValue={ data.category } options={optionsCat} selectedVal={ categoriaSelected }/>*/}
        </div>
        <div className={styles.item}>
          <label>Proceso</label>
          {/*<SelectButton defaultValue={ data.category } options={optionsCat} selectedVal={ categoriaSelected }/>*/}
        </div>
      </div>
      <div className={[styles.formGroup, styles.directionComents].join(' ')}>
        <div className={styles.item}>
          <label>Dirección</label>
          <input
            className={styles.viewMap}
            type="text"
            id="direccion"
            value={data.neighborhoodName}
            readOnly
          />
          {/* <div className={ styles.icon }></div> */}
        </div>
        <div className={styles.item}>
          <label>Comentarios</label>
          <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
          {/* <div className={''}>
            <p> { data.comment } </p>
          </div> */}
        </div>
      </div>
      <div className={[styles.formGroup, styles.directionComents].join(' ')}>
        <div className={styles.item}>
          <label>Foto</label>
          <div className={styles.photos}></div>
        </div>
        <div className={styles.item}>
          <label>Foto del Agente</label>
          <div className={styles.photos}></div>
        </div>
      </div>
    </>
  )

  const formAcceptReport = (
    <>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item}>
          <label>Folio</label>
          <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label>Fecha</label>
          <input type="text" id="fecha" value={moment(data.created_at).format("DD/MM/YYYY - HH:mm") ?? '-'} readOnly/>
        </div>
      </div>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item}>
          <label>Secretaría</label>
          <SelectButton placeholder={catalogOffices ? catalogOffices[0] : 'Cargando secretarías...'}
                        options={catalogOffices} label={'name'} value={'id'} initialValue={data.office_id} onSelect={secretariaSelected}/>
        </div>
        <div className={styles.item}>
          <label>Categoría</label>
          <SelectButton placeholder={catalogCategories ? catalogCategories[0] : 'Cargando categorías...'}
                        options={catalogCategories} label={'name'} value={'id'} initialValue={data.category_id} onSelect={categoriaSelected}/>
        </div>
      </div>
      <div className={[styles.formGroup].join(' ')}>
        <label>Dirección</label>
        <div className={styles.directionInput}>
          <input
            type="text"
            id="direccion"
            value={data.street + ', CP.' + data.zipcode + ', ' + data.state}
            readOnly
          />
          <div className={styles.itemMap} onClick={openMap}>
            <div className="locationDot">
              <LocationDot/>
            </div>
            <p>VER MAPA</p>
          </div>
        </div>
      </div>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item} onClick={openPhotoView}>
          <label>Foto</label>
          <div className={styles.fotoContainer} style={{backgroundImage: `url(${data.picture ?? ''})`}}>
          </div>
          {/* <div className={ styles.fotoContainer } style="background-image: url('https://api.reporteciudadano.app/storage`${fontSize}`')"></div> */}
        </div>
        <div className={[styles.item, styles.comment].join(' ')}>
          <label>Comentarios</label>
          <div className={styles.comments}>
            <p> {data.comment} </p>
          </div>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.priority}>
          <label>Prioridad</label>
          {/* { (requiredValue && isEmpty(selectedLevel) ) && <div className={styles.errorBox}>
            Selecciona un nivel de prioridad.
            </div>
          } */}
          <div className={styles.checkButtons}>
            {levels.map(option => (
              <CheckButton key={option.value} selected={selectedLevel} value={option.value} label={option.name} onClick={setLevel}/>
            ))}
          </div>
        </div>
      </div>
    </>
  )

  const formAddUser = (
    <>
      <div className={[styles.formGroup, styles.data].join(' ')}>
        <div className={styles.item}>
          <label>Nombre{'(s)'}</label>
          <input type="text" id="nombre" value={''}/>
        </div>
        <div className={styles.item}>
          <label>Apellido Paterno</label>
          <input type="text" id="apellido_paterno" value={data.created_at ?? '-'}/>
        </div>
        <div className={styles.item}>
          <label>Apellido Materno</label>
          <input type="text" id="apellido_materno" value={data.created_at ?? '-'}/>
          {/* <input type="text" id="folio" value="Tránsito y Vialidad" readOnly /> */}
        </div>
        <div className={styles.item}>
          <label>Secretaría</label>
          {/* <SelectButton defaultValue={ data.office } options={optionsSec} selectedVal={ secretariaSelected }/>*/}
        </div>
        <div className={styles.item}>
          <label>Categoría</label>
          {/* <SelectButton defaultValue={ data.category } options={optionsCat} selectedVal={ categoriaSelected }/>*/}
        </div>
        <div className={styles.item}>
          <label>Proceso</label>
          {/* <SelectButton defaultValue={ data.category } options={optionsCat} selectedVal={ categoriaSelected }/>*/}
        </div>
      </div>
      <div className={[styles.formGroup, styles.directionComents].join(' ')}>
        <div className={styles.item}>
          <label>Dirección</label>
          <input
            className={styles.viewMap}
            type="text"
            id="direccion"
            value={data.neighborhoodName}
            readOnly
          />
          {/* <div className={ styles.icon }></div> */}
        </div>
        <div className={styles.item}>
          <label>Comentarios</label>
          <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
          {/* <div className={''}>
            <p> { data.comment } </p>
          </div> */}
        </div>
      </div>
      <div className={[styles.formGroup, styles.directionComents].join(' ')}>
        <div className={styles.item}>
          <label>Foto</label>
          <div className={styles.photos}></div>
        </div>
        <div className={styles.item}>
          <label>Foto del Agente</label>
          <div className={styles.photos}></div>
        </div>
      </div>
    </>
  )

  useEffect(() => {
    if (!isEmpty(secretaria) || !isEmpty(categoria) || !isEmpty(selectedLevel)) {
      toUpdate({
        office: secretaria != null ? secretaria.id : null,
        category: categoria != null ? categoria.id : null,
        level: selectedLevel != null ? selectedLevel : null
      })
    }
  }, [secretaria, categoria, selectedLevel])

  return (
    <div className={[styles.formContainer, /*!isEmpty(report) ? styles[report] : ''*/].join(' ')}>
      {mapOpened && mapLocation}
      {openPhoto && photoView}
      {!mapOpened && !openPhoto && formAcceptReport}
    </div>
  )
}

export default ComponentForm
