import React, { useEffect, useState } from 'react'
import styles from './ComponentUser.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import {useAtom} from 'jotai'
import {
  accessToken,
  userData
} from '../../utilities/atoms'

const ComponentUser = ({data}) => {
  const [usuario, setUsuario] = useAtom(userData)
  const [token, setToken] = useAtom(accessToken)
  const [isOpen, setIsOpen] = useState(false)

  const showDropdown = () => {
    setIsOpen(!isOpen)
  }
  
  const logout = () => {
    setUsuario('')
    setToken(null)
  }

  return (
    <>
    <div className={styles.container}>
      <div className={ styles.user } onClick={showDropdown}>
        {/* <figure className={ styles.avatar }/> */}
        <div className={ styles.text }>{ usuario }</div>
        <div className={ styles.arrow }><FontAwesomeIcon icon={ regular('chevron-down') } /></div>
      </div>
      {isOpen && (
        <div className={styles.dropdown}>
          <div className={styles.dropdownContent}>
            <div className={styles.item}>
              <FontAwesomeIcon icon={light('circle-user')} className={styles.icon}/>
              <div className={styles.label}>Mi Perfil</div>
            </div>
            <div className={styles.item}>
              <FontAwesomeIcon icon={light('circle-question')} className={styles.icon}/>
              <div className={styles.label}>Ayuda</div>
            </div>
            <hr className={styles.separator} />
            <div className={ [styles.item, styles.redText, styles.logout].join(' ') }>
              <FontAwesomeIcon icon={light('power-off')} className={styles.icon}/>
              <div className={styles.label} onClick={ logout }>Salir del Sistema</div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
    )
}

export default ComponentUser
