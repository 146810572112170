import React, { useEffect, useState } from 'react'
import styles from "./ReportInfo.module.scss"
import moment from 'moment'
import 'moment-timezone';
import { ReactComponent as LocationDot } from '../../../assets/images/location-dot.svg'
import { Map, Button } from '../../../components/reporteCiudadano'
import { isEmpty } from 'lodash'
import { useAtom } from "jotai"
import { accessToken } from "../../../utilities/atoms"

const ReportInfo = ({data, toUpdate}) => {
  const [mapOpened, setMapOpened] = useState(false)
  const [openPhoto, setOpenPhoto] = useState(false)
  const [currentPhoto, setCurrentPhoto] = useState(null)
  const [token] = useAtom(accessToken)
  const [status, setStatus] = useState(data.status)
  const [condoned, setCondoned] = useState(data.fee_condoned !== null && data.fee_condoned !== undefined)
  const attending_at = moment(data.attending_at).tz('America/Monterrey').format('YYYY-MM-DD HH:mm:ss');
  const completed_at = moment(data.completed_at).tz('America/Monterrey').format('HH:mm:ss');
  const employee = data.employee

  const options = {
    nuevo: 'new',
    rechazado: 'rejected',
    aprobado: 'accepted',
    atendiendo: 'in progress',
    solucionado: 'completed',
    'no solucionado': 'completed with error',
    cerrado: 'closed',
    condonado: 'condoned',
    'no atendido' : 'ignored'
  }

  const gruas = {
    '': 'Ninguna',
    pacheco: 'Grúas Pacheco',
    victoria: 'Grúas Victoria',
    transito: 'Grúas Tránsito',
  }

  const closeItems = () => {
    setMapOpened(false)
    setOpenPhoto(false)
    setCurrentPhoto(null)
  }

  const openMap = () => {
    console.log('Openmap')
    if (!mapOpened) {
      setMapOpened(true)
    }
  }

  useEffect(() => {
    if (!isEmpty(currentPhoto)) {
      setOpenPhoto(true);
    }
  }, [currentPhoto])


  useEffect(() => {
    if (!isEmpty(status) || !isEmpty(condoned)) {
      const updateData = {
        status: status != null ? status : null,
        office: data.office_id != null ? data.office_id : null,
        category: data.category_id != null ? data.category_id : null,
        level: data.level_raw != null ? data.level_raw : null,
      }

      if (condoned === 'true') {
        const currentDay = moment().toISOString()
        if (isEmpty(data.fee_condoned)) {
          updateData.fee_condoned = currentDay
        }
      } else {
        updateData.fee_condoned = null
      }
      console.log(updateData)
      toUpdate(updateData)
    }
  }, [status, condoned])

  useEffect(() => {
    if (data.status && Object.keys(options).includes(data.status)) {
      setStatus(options[data.status]);
    }
  }, [data.status]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const photoView = (
    <div className={styles.mapContainer}>
      <div className={styles.photoView} style={{
        backgroundImage: `url(${
          currentPhoto ?? ''
        })`
      }}>
      </div>
      <Button onClick={closeItems} type="default">Cerrar Foto</Button>
    </div>
  )

  const mapLocation = (
    <div className={styles.mapContainer}>
      <Map
        zoom={20}
        lat={data.lat}
        lng={data.lng}
      />
      <Button onClick={closeItems} type="default">Cerrar Mapa</Button>
    </div>
  )

  const FormInfo = (
    <>
      <div className={styles.formGroup}>
        <div className={styles.item}>
          <label htmlFor="folio">Folio</label>
          <input type="text" id="folio" value={data.folio ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label htmlFor="created_at">Fecha de reporte</label>
          <input type="text" id="fecha" value={moment(data.created_at).tz('America/Monterrey').format("DD/MM/YYYY - HH:mm") ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label htmlFor="date_atention">Hora de Atención</label>
          <input type="text" id="fecha" value={attending_at} readOnly/>
        </div>
        <div className={styles.item}>
          <label htmlFor="date_solution">Hora de Solucionado</label>
          <input type="text" id="fecha" value={completed_at !== 'Invalid date' ? completed_at : '-'} readOnly/>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={styles.item}>
          <label htmlFor="secretaria">Secretaría</label>
          <input type="text" id="secretaria" value={ data.office ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label htmlFor="categoria">Categoría</label>
          <input type="text" id="categoria" value={ data.category ?? '-'} readOnly/>
        </div>
        <div className={styles.item}>
          <label htmlFor="process">Proceso</label>
          <select id="process" value={status} onChange={handleStatusChange}>
            {Object.entries(options).map(([key, value]) => (
              <option key={value} value={value}>{key.charAt(0).toUpperCase() + key.slice(1)}</option>
            ))}
          </select>
          
        </div>
        <div className={styles.item}>
          <label htmlFor="employee">Personal</label>
          <input type="text" id="employee" value={employee} readOnly/>
        </div>
      </div>
      <div className={styles.formGroup}>
        <div className={[styles.item, styles.full].join(' ')}>
          <label htmlFor="direccion">Dirección</label>
          <div className={styles.directionInput}>
            <input type="text" id="direccion" value={(data.street + ', CP.' + data.zipcode + ', ' + data.state).slice(0,80)} readOnly/>
            <div className={styles.itemMap} onClick={openMap}>
              <div className="locationDot">
                <LocationDot/>
              </div>
              <p>VER MAPA</p>
            </div>
          </div>
        </div>
      </div>
      <div className={[styles.formGroup, styles.photosContainer].join(' ')}>
        <div className={ [styles.item, styles.open].join(' ')}>
          <label htmlFor="photo_report">Foto del reporte</label>
          <div className={styles.fotoContainer} onClick={(event) => setCurrentPhoto(data.picture)} style={{backgroundImage: `url(${data.picture ?? ''})`}}>
          <div className={ [ styles.pill, styles[data.level] ?? '' ].join(' ') }>
            <div className={ [ styles.square, styles[data.level] ?? '' ].join(' ') }></div><p>{data.level?.toUpperCase()}</p>
          </div>
          </div>
          <label htmlFor="coments_user">Comentarios del ciudadano</label>
          <div className={styles.dataText}>{data.comment ?? '-'}</div>
        </div>
        <div className={ [styles.item, styles.open].join(' ')}>
          <label htmlFor="photo_employee">Foto del Personal</label>
          <div className={styles.fotoContainer} onClick={(event) => setCurrentPhoto(data.employee_picture)} style={{backgroundImage: `url(${data.employee_picture ?? ''})`}}>
          </div>
          <label htmlFor="coments_employee">Comentarios del Personal</label>
          <div className={styles.dataText}>{data.employee_comments ?? '-'}</div>
        </div>
      </div>
    </>
  )

  return (<>
    <div className={ [styles.Formcontent, openPhoto && styles.centered, mapOpened && styles.centered].join(' ') }>
      {!mapOpened && !openPhoto && FormInfo}
      {mapOpened && mapLocation}
      {openPhoto && photoView}
    </div>
  </>)
}

export default ReportInfo