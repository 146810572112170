import React, { useEffect, useState } from 'react'
import styles from './ComponentCheckButton.module.scss'

function ComponentCheckButton({ value, label, onClick, selected }) {
  const [selectedLevel, setSelectedLevel] = useState(selected)

  const setLevel = (level) => {
    setSelectedLevel(level)
    onClick(level)
  }

  useEffect(() => {
    setSelectedLevel(selected)
  }, [selected])

  let squareClass

  console.log(selectedLevel)

  if (value === 'low') {
    squareClass = styles.square + ' ' + styles['square--leve']
  } else if (value === 'medium') {
    squareClass = styles.square + ' ' + styles['square--moderado']
  } else if (value === 'high') {
    squareClass = styles.square + ' ' + styles['square--grave']
  }

  return (
    <div className={styles.container}>
      <button className={[styles.button, selectedLevel === value ? styles.selected : ''].join(' ')} onClick={() => setLevel(value)}>
        <div className={squareClass}></div>
        <span className={styles.text}><p>{label}</p></span>
      </button>
    </div>
  )
}

export default ComponentCheckButton
