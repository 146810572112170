import React from 'react'
import { Link } from 'react-router-dom'
import styles from "./Module.module.scss"
import { ReactComponent as ReportsIcon } from '../../../assets/images/reports.svg'
import { ReactComponent as RolesIcon } from '../../../assets/images/roles.svg'
import { ReactComponent as StatisticsIcon } from '../../../assets/images/statistics.svg'
import { ReactComponent as NotificationsIcon } from '../../../assets/images/notifications.svg'


const Module = (props) => {
  let icon

  switch(props.icon) {
    case "reports":
      icon = <ReportsIcon className={styles.icon} />
      break;
    case "roles":
      icon = <RolesIcon className={styles.icon} />
      break;
    case "statistics":
      icon = <StatisticsIcon className={styles.icon} />
      break;
    case "notifications":
      icon = <NotificationsIcon className={styles.icon} />
      break;
    default:
      icon = null;
  }
  return (
    <div className={ [ styles.module,  props.disabled ? styles[props.disabled] : ''].join(' ') }>
      <Link to={ props.route }>
        <div className={ styles.itemContainer }>

          <figure className={ [ styles.item ].join(' ') }>
            {icon}
          </figure>
          {/* <svg className={`${styles.item} ${styles[props.icon]}`}>
            <p>{ props.label }</p>
          </svg> */}
          {/* <svg className={ styles.item }>
            <use className={ styles.icon } xlinkHref={ '#' + props.icon }/>
          </svg> */}
          <p>{ props.label }</p>
        </div>
      </Link>
    </div>
  )
}

export default Module
