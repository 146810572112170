import React from 'react'
import ComponentMenu from './ComponentMenu'
import ComponentUser from './ComponentUser'
import ComponentOptions from './ComponentOptions'
import ComponentPage from './ComponentPage'
import DataTableComponent from './DataTableComponent'
import ComponentModal from './ComponentModal'
import ComponentModalView from './ComponentModalView'
import ComponentForm from './ComponentForm'
import ButtonComponent from './ButtonComponent'
import ComponentSelectButton from './ComponentSelectButton'
import ComponentCheckButton from './ComponentCheckButton'
import TagComponent from "../ui/TagComponent"
import MapComponent from './MapComponent'

export const Menu = (props) => {
  return <ComponentMenu {...props}>{props.children}</ComponentMenu>
}

export const User = (props) => {
  return <ComponentUser {...props}>{props.children}</ComponentUser>
}

export const Options = (props) => {
  return <ComponentOptions {...props}>{props.children}</ComponentOptions>
}

export const Page = (props) => {
  return <ComponentPage {...props}>{props.children}</ComponentPage>
}

export const Datatable = (props) => {
  return <DataTableComponent {...props}>{props.children}</DataTableComponent>
}

export const Modal = (props) => {
  return <ComponentModal {...props}>{props.children}</ComponentModal>
}

export const ModalView = (props) => {
  return <ComponentModalView {...props}>{props.children}</ComponentModalView>
}

export const Form = (props) => {
  return <ComponentForm {...props}>{props.children}</ComponentForm>
}

export const Button = (props) => {
  return <ButtonComponent {...props}>{props.children}</ButtonComponent>
}

export const SelectButton = (props) => {
  return <ComponentSelectButton {...props}>{props.children}</ComponentSelectButton>
}

export const CheckButton = (props) => {
  return <ComponentCheckButton {...props}>{props.children}</ComponentCheckButton>
}

export const Tag = (props) => {
  return <TagComponent {...props}>{props.children}</TagComponent>
}

export const Map = (props) => {
  return <MapComponent {...props}>{props.children}</MapComponent>
}