import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import styles from './ComponentSelectButton.module.scss'

const ComponentSelectButton = ({placeholder, options, label= 'name', value = 'value', initialValue = null, onSelect = (index) => {}}) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  const [isOpen, setIsOpen] = useState(false)

  const handleOptionClick = (index) => {
    setCurrentIndex(index)
    onSelect(index)
  }

  const showDropdown = () => {
    setIsOpen(!isOpen)
  }
  const selectButtonClasses = `${styles.selectButton} ${isOpen ? styles.open : ''}`

  useEffect(() => {
    if (options != null) {
      const findValue = (element) => {
        console.log('item', element)
        console.log('val', element[value])
        console.log('saved', initialValue)
        return element[value] == initialValue
      }

      let index = options.findIndex(findValue)
      console.log(index)
      setCurrentIndex(index)
    }
  }, [options])

  return (
    <div className={selectButtonClasses} onClick={showDropdown}>
      <div className={styles.selectedValue}>
        <p>{options == null ? placeholder : (currentIndex >= 0 ? options[currentIndex][label] : 'Selecciona una opción...')}</p>
      </div>

      {options != null ? (<>
        <FontAwesomeIcon icon={regular('chevron-down')} className={styles.dropdownIcon}/>
        <ul className={styles.optionsList}>
          {options.map((option, index) => (
            <li key={option[value]} onClick={() => handleOptionClick(index)}>
              {option[label]}
            </li>
          ))}
        </ul>
      </>) : null}
    </div>
  )
}

export default ComponentSelectButton
