import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useAtom } from 'jotai'
import styles from './CreateUser.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { isEmpty } from 'lodash'
import { accessToken, } from '../../../utilities/atoms'
import { ModuleType } from '../../../components/enums'
import { jwtDecode } from 'jwt-decode'

const CreateUser = forwardRef(({toCreate, toEdit, data = null, type = null}, ref) => {
  const eyeIcon = regular('eye')
  const eyeSlashIcon = regular('eye-slash')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(true)
  const [name, setName] = useState('')
  const [lastname, setLastname] = useState('')
  const [secondLastname, setSecondLastname] = useState('')
  const [identifier, setIdentifier] = useState('')
  const [phone, setPhone] = useState('')
  const [role, setRole] = useState('')
  const [email, setEmail] = useState('')
  const roles = useRef([])
  const [_readOnly, setReadOnly] = useState(false)
  const [errors, setErrors] = useState(true)
  const [token] = useAtom(accessToken)
  const [moduleReport, setModuleReport] = useState('')

  const rolesTV = {
    admin: "Administrador",
    officer: "Oficial de campo"
  };

  const rolesSP = {
    'admin-services': "Administrador",
    'employee-services': "Persona"
  }

  useImperativeHandle(ref, () => ({
    setErrorBag(errors) {
      handleErrorBag(errors)
    }
  }))

  const handleErrorBag = (errors) => {
    setErrors(errors)
  }

  const validatePhone = (phone) => {
    const phoneRegex = /^[0-9]{10}$/
    return phoneRegex.test(phone)
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    console.log(emailRegex)
    return emailRegex.test(email)
  };

  const roleMappings = {
    admin: ["admin", "user"],
    officer: ["officer", "user"],
    "admin-services": ["admin-services", "user"],
    "employee-services": ["employee-services", "user"]
  }

  useEffect(() => {
    console.debug('errors', errors)
  }, [errors])

  useEffect(() => {
    if (isEmpty(moduleReport) && !isEmpty(token)) {
      let decodedToken = jwtDecode(token)
      setModuleReport(ModuleType.type(decodedToken.roles))
    }
  }, [moduleReport, token])

  useEffect(() => {
    if (!isEmpty(type) && type === 'create') {
      
    }
  }, [type]);

  useEffect(() => {

    if (!isEmpty(role)) {
      if (roleMappings.hasOwnProperty(role)) {
        roles.current = roleMappings[role];
      }
    }
    if ( type === 'create' ) {
      const createUser = { 
        email: email,
        password: password,
        password_confirmation: password,
        name: name,
        lastname: lastname,
        second_lastname: secondLastname,
        identifier: identifier,
        phone: phone,
        role: role,
      }
      toCreate(createUser)
    }

    if ( !isEmpty(data) && !isEmpty(type) && type === 'edit' 
      /*&&
      !isEmpty(name) &&
      validateEmail(email) &&
      !isEmpty(lastname) &&
      !isEmpty(secondLastname) &&
      !isEmpty(identifier) &&
      validatePhone(phone) &&
      !isEmpty(role)*/
     ) {
      const updatedData = {
        id: data.id,
        email: email,
        name: name,
        lastname: lastname,
        second_lastname: secondLastname,
        identifier: identifier,
        phone: phone,
        roles: roles.current,
        neighborhood: data.neighborhood,
        neighborhood_id: data.neighborhood_id,

      }

      if (!isEmpty(password)) {
        updatedData.password = password
        updatedData.password_confirmation = password
      }

      toEdit(updatedData)
    }

  }, [
    name,
    email,
    password,
    lastname,
    secondLastname,
    identifier,
    phone,
    role,
  ]);

  useEffect(() => {
    if (!isEmpty(data) && !isEmpty(type) && type !== 'create') {
      setName(data.name)
      setEmail(data.email)
      setLastname(data.lastname)
      setSecondLastname(data.second_lastname)
      setIdentifier(data.identifier)
      setPhone(data.phone)
      setReadOnly(true)
      let foundRole = Object.keys(roleMappings).find(role => data.roles.includes(role))
      roles.current = roleMappings[foundRole]
      setRole(foundRole)
    } else if (!isEmpty(data) && !isEmpty(type) && type === 'create') {
      setName(data.name)
      setEmail(data.email)
      setLastname(data.lastname)
      setSecondLastname(data.second_lastname)
      setIdentifier(data.identifier)
      setPhone(data.phone)
      setPassword(data.password)
      setRole(data.role)
    }
  }, [data]);

  const ShowPass = () => {
    setShowPassword(!showPassword)
  }
  const passwordInputType = showPassword ? 'text' : 'password'

  const optionsSec = [
    { label: 'Oficial de Tránsito', value: 'Administrador' },
  ]


  const roleList = (key, value) => (
    <option key={key} value={key}>{value}</option>
  )

  return (
    <div className={styles.Formcontent} ref={ref}>
      <div className={styles.formGroup}>
        <div className={styles.item}>
          <label htmlFor="name">Nombre(s) {/*errors?.nombre?.message*/}</label>
          <input
            type="text"
            name={'name'}
            placeholder={'Juan Antonio'}
            value={name}
            onChange={(event) => setName(event.target.value)}
            // readOnly={_readOnly}
          />
          { errors?.name?.message && 
            <div className={styles.errorBox}>
              {errors?.name?.message}
            </div>
          }
        </div>
        <div className={styles.item}>
          <label htmlFor="lastname">Primer apellido</label>
          <input
            type="text"
            name={'lastname'}
            placeholder={'Gutiérrez'}
            value={lastname}
            onChange={(event) => setLastname(event.target.value)}
            // readOnly={_readOnly}
          />
          { errors?.lastname?.message && 
            <div className={styles.errorBox}>
              {errors?.lastname?.message}
            </div>
          }
        </div>
        <div className={styles.item}>
          <label htmlFor="second_lastname">Segundo apellido</label>
          <input
            type="text"
            name={'second_lastname'}
            placeholder={'Hernández'}
            value={secondLastname}
            onChange={(event) => setSecondLastname(event.target.value)}
            // readOnly={_readOnly}
          />
          { errors?.secondlastname?.message && 
            <div className={styles.errorBox}>
              {errors?.secondlastname?.message}
            </div>
          }
        </div>
      </div>

      <div className={styles.formGroup}>
        <div className={styles.item}>
          <label htmlFor="identifier">Número de identificación</label>
          <input
            type="text"
            name={'identifier'}
            placeholder={'TC-190884'}
            value={identifier}
            onChange={(event) => setIdentifier(event.target.value)}
            // readOnly={_readOnly}
          />
          { errors?.identifier?.message && 
            <div className={styles.errorBox}>
              {errors?.identifier?.message}
            </div>
          }
        </div>
        <div className={styles.item}>
          <label htmlFor="phone">Teléfono</label>
          <input
            type="text"
            name={'phone'}
            placeholder={'Teléfono'}
            value={phone}
            onChange={(event) => {
              const numericValue = event.target.value.replace(/\D/, '')
              setPhone(numericValue.slice(0, 10))
            }}
            // readOnly={_readOnly}
          />
          { errors?.phone?.message && 
            <div className={styles.errorBox}>
              {errors?.phone?.message}
            </div>
          }
        </div>
        <div className={styles.item}>
          <label htmlFor="role">Rol</label>
          <select
            name="role"
            id="role"
            value={role}
            onChange={(event) => setRole(event.target.value)}
          >
            {moduleReport === 'reports' ? Object.entries(rolesTV).map(([key, value], index) => {
              if (index === 0 && role === '') {
                setRole(key)
              }
              return roleList(key, value)
            }) : ( moduleReport === 'public_reports' ? Object.entries(rolesSP).map(([key, value], index) => {
              if (index === 0 && role === '') {
                setRole(key)
              }
              return roleList(key, value)
            }) : '')}
          </select>
          { errors?.role?.message && 
            <div className={styles.errorBox}>
              {errors?.role?.message}
            </div>
          }
        </div>
      </div>

      <div className={[styles.formGroup, styles.password].join(' ')}>
        <div className={[styles.item, styles.full].join(' ')}>
          <label htmlFor="email">Correo electrónico</label>
          <input
            type="text"
            name={'email'}
            placeholder={'juan@victoria.gob.mx'}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            readOnly={_readOnly}
          />
          { errors?.email?.message && 
            <div className={styles.errorBox}>
              {errors?.email?.message}
            </div>
          }
        </div>
        <div className={[styles.item, styles.full].join(' ')}>
          <label htmlFor="password">Contraseña</label>
          <input
            type={passwordInputType}
            placeholder="••••••••"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            // readOnly={_readOnly}
          />
          <FontAwesomeIcon
            icon={showPassword ? eyeIcon : eyeSlashIcon}
            flip="horizontal"
            className={styles.icon}
            onClick={ShowPass}
          />
          { errors?.password?.message && 
            <div className={styles.errorBox}>
              {errors?.password?.message}
            </div>
          }
        </div>
      </div>
    </div>
  )
})

export default CreateUser
