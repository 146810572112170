import React, { createContext, useContext, useMemo, useReducer, useRef, useState } from 'react'

import { ModalView } from '../components/reporteCiudadano'

const ModalViewContext = createContext(null)

export function ModalViewProvider({children, cancelable = true}) {
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const [show, setShow] = useState(false)


  const negativeActionDefault = {
    title: 'OK',
    onClick: () => {
      setShow(false)
    }
  }
  
  const content = useRef('')
  const title = useRef('')
  //const styles = useRef(null)
  //const defaultAction = useRef(null)
  const positiveAction = useRef(null)
  const negativeAction = useRef(null)
  const defaultAction = useRef(null)
  const actionAction = useRef(null)
  const destructiveAction = useRef(null)
  const onPrint = useRef(null)

  function showModalView() {
    //loggerDebug('isVisible', isVisible())
    if (isModalViewVisible()) {
      forceUpdate()
    }
    //loggerDebug('Muestra modal')
    setShow(true)
  }

  function hideModalView() {
    //loggerDebug('Oculta modal')
    setShow(false)
  }

  function destroyModalView() {
    //loggerDebug('Oculta modal')
    setShow(false)
    setupModalView({
      title: null,
      content: null,
    })
  }

  function isModalViewVisible() {
    return show
  }

  function setupModalView(userInfo) {
    title.current = userInfo.title
    content.current = userInfo.content
    //styles.current = userInfo.styles

    if (userInfo.actions) {
     positiveAction.current = userInfo.actions.positive
     negativeAction.current = userInfo.actions.negative
     defaultAction.current = userInfo.actions.default
     actionAction.current = userInfo.actions.action
     destructiveAction.current = userInfo.actions.destructive
     onPrint.current = userInfo.actions.print
      //defaultAction.current = userInfo.actions.default
    } else {
     negativeAction.current = negativeActionDefault
    }
  }

  const value = useMemo(() => {
    return {
      //isVisible,
      showModalView,
      hideModalView,
      destroyModalView,
      setupModalView
    }
  }, [show])

  return <ModalViewContext.Provider value={ value }>
    <ModalView show={ show } cancelable={ cancelable }
      onClose={ negativeAction.current?.onClick ?? negativeActionDefault.onClick }
      onAccept={ positiveAction.current?.onClick ?? (actionAction.current?.onClick) }
      onDestroy={ destructiveAction.current?.onClick }
      onDefault={ defaultAction.current?.onClick }
      onPrint={ onPrint.current?.onClick }
      title={ title.current }
      /*buttonType={ styles.current?.buttonType }*/
      positiveButtonTitle={ positiveAction.current?.title }
      negativeButtonTitle={ negativeAction.current?.title }
      defaultButtonTitle={ defaultAction.current?.title }
      actionButtonTitle = { actionAction.current?.title }
      destructiveButtonTitle = { destructiveAction.current?.title }
      onPrintTitle = { onPrint.current?.title }
    >
      { content.current }
    </ModalView>
    { children }
  </ModalViewContext.Provider>
}

export function useModalView() {
  const context = useContext(ModalViewContext)

  if (!context) {
    throw new Error('useModalView debe estar dentro del proveedor ModalViewContext')
  }

  return context
}
