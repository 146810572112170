import React from "react"
import { Link } from "react-router-dom"
import styles from "./ComponentMenu.module.scss"

const ComponentMenu = ({ data, action }) => {
  return (
    <>
      <div className={styles.menu}>
        {data.map((link, index) => (
          <Link key={'menu' + index} to={link.href}>
            <label className={ link.style ? styles[link.style] : ''}>{link.text}</label>
          </Link>
        ))}
      </div>
    </>
  )
}

export default ComponentMenu;
